export const markets = [
    {
        sportId: 1,
        en_name: "Soccer",
        fr_name: "Football",
        displayId :"1x2",
        markets: [
            {
                "id": 1,
                "specValue": 0,
                "name": "1x2",
                "fr_name": "1x2",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                "key": "1x2",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 10,
                "specValue": 0,
                "name": "Double chance",
                "fr_name": "Double chance",
                "bg": "Double Chance ",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id":"9"
                    },
                    {
                        "name": "X 0R 2",
                        "shortName": "X OU 2",
                        "id":"11"
                    },
                    {
                        "name": "1 OR 2",
                        "shortName": "1 OU 2",
                        "id":"10"
                    }
                    
                ]
            },
            {
                "id": 18,
                "specValue": 0,
                "name": "Under/Over 1.5",
                "fr_name": "Moins de/Plus de 1.5",
                "bg": "Over/Under 1.50",
                "columns": 2,
                "inColumn": 3,
                "key": "total=1.5",
                "selections": [
                    {
                        "name": "under",
                        "shortName": "Moins de",
                        "id":"13"
                    },
                    {
                        "name": "over",
                        "shortName": "Plus de",
                        "id":"12"
                    }
                ]
            },
            {
                "id": 18,
                "specValue": 0,
                "name": "Under/Over 2.5",
                "fr_name": "Moins de/Plus de 2.5",
                "bg": "Over/Under 2.50",
                "columns": 2,
                "inColumn": 3,
                "key": "total=2.5",
                "selections": [
                    {
                        "name": "under",
                        "shortName": "Moins de",
                        "id":"13"
                    },
                    {
                        "name": "over",
                        "shortName": "Plus de",
                        "id":"12"
                    }
                ]
            }
        ]
    },
    {
        sportId: 2,
        en_name: "Basketball",
        fr_name: "Basketball",
        displayId :"1x2",
        markets: [
            {
                "id": 1,
                "specValue": "1x2",
                "name": "1x2",
                "fr_name": "1x2",
                "columns": 3,
                "inColumn": 1,
                "mobile":true,
                "key": "1x2",
                "class":"three-markets",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 219,
                "specValue": "winner-incl.-overtime-",
                "name": "Winner (incl. overtime)",
                "fr_name":"Vainqueur (inclus prolongations)",
                "columns": 2,
                "inColumn": 2,
                "key": "winner-incl.-overtime-",
                "class":"two-markets",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"4"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"5"
                    }
                ]
            }
        ]
    },
    {
        sportId: 5,
        en_name: "Tennis",
        fr_name: "Tennis",
        displayId:"winner",
        markets: [
            {
                id: 186,
                specValue: 0,
                name: "Winner",
                fr_name: "Vainqueur",
                columns: 2,
                inColumn: 1,
                print:true,
                key: "winner",
                selections: [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : "4"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : "5"
                    }
                ]
            },
            {
                id: 198,
                specValue: 0,
                name: "Odd/even games",
                fr_name: "Pair/impair jeux",
                columns: 2,
                inColumn: 1,
                key: "doubles-match",
                selections: [
                    {
                        "name": "odd",
                        "shortName": "impair",
                        "id" : "70"
                    },
                    {
                        "name": "even",
                        "shortName": "pair",
                        "id" : "72"
                    }
                ]
            },
        ]
    },
    {
        sportId: 12,
        en_name: "Rugby",
        fr_name: "Rugby",
        displayId :"1x2",
        "markets": [
            {
                "id": 1,
                "specValue": "1x2",
                "name": "1x2",
                "fr_name": "1x2",
                "columns": 3,
                "inColumn": 1,
                "mobile":true,
                "key": "1x2",
                "class":"three-markets",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id":1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id":2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id":3
                }
                ]
            },
            {
                "id": 10,
                "specValue": "double-chance",
                "key": "double-chance",
                "name": "Double Chance",
                "fr_name": "Double Chance",
                "columns": 3,
                "inColumn": 2,
                "class":"three-markets",
                "selections": [
                {
                    "name": "1 OR X",
                    "shortName": "1 OU X",
                    "id": "9"
                },
                {
                    "name": "X OR 2",
                    "shortName": "X OU 2",
                    "id": "10"
                },
                {
                    "name": "1 0R 2",
                    "shortName": "1 OU 2",
                    "id": "11"
                }
                ]
            }
        ]
    },
    {
        sportId: 20,
        en_name: "Table Tennis",
        fr_name: "Table Tennis",
        displayId:"winner",
        markets: [
            {
                id: 186,
                specValue: 0,
                name: "Winner",
                fr_name: "Vainqueur",
                columns: 2,
                inColumn: 1,
                print:true,
                key: "winner",
                selections: [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : "4"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : "5"
                    }
                ]
            },
            {
                id: 198,
                specValue: 0,
                name: "Odd/even games",
                fr_name: "Pair/impair jeux",
                columns: 2,
                inColumn: 1,
                key: "doubles-match",
                selections: [
                    {
                        "name": "odd",
                        "shortName": "impair",
                        "id" : "70"
                    },
                    {
                        "name": "even",
                        "shortName": "pair",
                        "id" : "72"
                    }
                ]
            },
        ]
    },
    {
        "sportId": 4,
        "en_name": "Ice Hockey",
        "fr_name": "Hockey sur glace",
        displayId:"1x2",
        "markets": [
            {
            "id": 1,
            "specValue": "1x2",
            "name": "1x2",
            "fr_name" : "1x2",
            "columns": 3,
            "inColumn": 1,
            key: "1x2",
            "mobile":true,
            "class":"three-markets",
            "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id": 1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id": 2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id": 3
                }
            ]
            },
            {
                "id": 10,
                "specValue": "double-chance",
                "name": "Double chance",
                "fr_name": "Double chance",
                "columns": 3,
                "inColumn": 2,
                key: "doubles-chance",
                "class":"three-markets",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id": "9"
                    },
                    {
                        "name": "X OR 2",
                        "shortName": "X OU 2",
                        "id": "11"
                    },
                    {
                        "name": "1 0R 2",
                        "shortName": "1 OU 2",
                        "id": "10"
                    }
                ]
            }
        ]
    },
    {
        "sportId": 6,
        "en_name": "Handball",
        "fr_name": "Handball",
        displayId:"1x2",
        "markets": [
            {
                "id": 1,
                "specValue": "1x2",
                "name": "1x2",
                "fr_name": "1x2",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                key: "1x2",
                "class":"three-markets",
                "mobile":true,
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 10,
                "specValue": "double-chance",
                "name": "Double chance",
                "fr_name": "Double chance",
                "bg": "Double Chance ",
                "columns": 3,
                "inColumn": 2,
                key: "double-chance",
                "class":"three-markets",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id":"9"
                    },
                    {
                        "name": "X 0R 2",
                        "shortName": "X OU 2",
                        "id":"11"
                    },
                    {
                        "name": "1 OR 2",
                        "shortName": "1 OU 2",
                        "id":"10"
                    }
                    
                ]
            },
        ]
    },
    {
        "sportId": 3,
        "en_name": "Baseball",
        "fr_name": "Baseball",
        displayId:"1x2",
        "markets": [
            {
                "id": 1,
                "specValue": 0,
                "name": "1X2",
                "fr_name": "1x2",
                "columns": 3,
                "class":"three-markets",
                "inColumn": 1,
                "mobile":true,
                key: "1x2",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 251,
                "specValue": 0,
                "name": "Winner (incl. extra innings)",
                "fr_name":"Vainqueur (inclus manches supplémentaires)",
                "columns": 2,
                "inColumn": 2,
                key: "money-line",
                "class":"two-markets",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1"
                    },
                    {
                        "name": "2",
                        "shortName": "2"
                    }
                ]
            },
            
        ]
    },
]