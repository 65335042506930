<template>
  <div class="overlay-menu " :class="{ 'sidenavopen' : display}" @click="toggleSidebar">
    <div class="overlay-menu-container">
      <div class="overlay-menu-section">
        <div class="overlay-menu-header d-flex">
          <div class="hambager sidebar-button" @click="toggleSidebar">
            <div class="hambager__bar topopen sidebar-button" @click="toggleSidebar"></div> 
            <div class="hambager__bar short midopen"></div>
            <div class="hambager__bar botopen sidebar-button" @click="toggleSidebar"></div>
          </div>
          <img :src="`${publicPath}images/logo1.png`" alt="Logo" class="img-logo" >
        </div>
        <div class="overlay-menu-body">
          <ul class="list-unstyled">
            <li>
              <router-link to = "/"><i class="icofont-home"></i> {{ $t('header.sport') }}</router-link>
            </li>
            <li>
              <router-link to = "/jackpots"><i class="icofont-files-stack"></i> {{ $t('header.mega-jackpot') }}</router-link>
            </li>
            <li>
              <router-link to = "/casino-home"><i class="icofont-canoe"></i> {{ $t('casino.casino') }}</router-link>
            </li>
            <li>
              <router-link to = "/virtuals"><i class="icofont-dice"></i> {{ $t('casino.virtuals') }}</router-link>
            </li>
            <li>
              <router-link to = "/promotions"><i class="icofont-badge"></i> {{ $t('header.promotions') }}</router-link>
            </li>
            <li>
              <router-link to = "/leaderboard"><i class="icofont-award"></i> {{ $t('header.leaderboard') }}</router-link>
            </li>
            <li>
              <router-link to = "/app"><i class="icofont-smart-phone"></i> {{ $t('header.app') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="overlay-pos">
          <div class="overlay-menu-body">
            <div class="card">
              <h4 class="line3 center standart-h4title">{{ $t('footer.contact-title')  }}</h4>
              <p>
                {{ $t('footer.legal-notice') }}
              </p>
            </div>
          </div>
          <div class="overlay-menu-body">
            <div class="card">
              <h4 class="line3 center standart-h4title">{{ $t('footer.legal') }}</h4>
              <p><span class="age18">{{ $t('footer.legal-age')  }}</span> {{ $t('footer.legal-age-notice') }}</p>
              <p>{{ $t('footer.legal-warning') }}</p>
            </div>
          </div>
          <p>©{{ $t(currentDate) }} {{ $t('footer.copyright') }}: <a href="/"><strong style="color: #f7941e;"> {{ $t('footer.contact-title') }}</strong></a> | {{ $t('footer.rights') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      show:{
        type: Boolean,
        default:false
      }
    },
    data() {
      return {
        check: this.$props.show,
        publicPath: process.env.BASE_URL,
        currentDate : new Date().getFullYear(),
      }
    },
    computed: {
      display() {
        return this.$props.show
      }
    },
    methods: {
      toggleSidebar: function() {
        this.$emit('emitToggle', this.display);
      }
    }
  }
</script>
<style scoped>
  
  .overlay-pos {
    position: absolute;
    bottom: 0;
  }
  .age18 {
    float: left;
    overflow: hidden;
    border: 2px solid #fff;
    text-shadow: none;
    position: relative;
    padding: 8px 10px;
    border-radius: 6px;
    margin-right: 10px;
    background: #f7941e;
    font-weight: 600;
    color: #fff;
  }
 .standart-h4title {
    text-transform: uppercase;
    /* border-bottom: 1px solid #fff; */
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .spce {
    margin: 15px 0px;
  }
  .whatsapp {
    background-color: green;
    padding: 5px 6px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 15px;
    text-align: -webkit-center;
    font-size: 15px;
  }
  .call {
    background-color: #f7941e;
    padding: 5px 6px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 15px;
    text-align: center;
    font-size: 15px;
  }
</style>