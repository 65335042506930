<template>
  <span class="odd"><i class="icofont-lock"></i></span>
</template>
<script>
  export default {
    props:['odd']
  }
</script>
<style scoped>
  .odd {
    padding: 8px 12px;
    border-radius: 5px;
    background-color: var(--bg-blue);
    color: var(--a-text);
    text-align: center;
    cursor: not-allowed;
  }
  .odd.selected {
    background-color: var(--bg-orange);
  }
  .odd i {
    margin-right: 0px;
  }
</style>