import axios from 'axios';
import Auth from "@/services/auth.js"
import store from "@/store/store.js"


export default function setup() {
    axios.interceptors.request.use(function(config) {
        const authToken = Auth.getAuthorizationToken()
        if( !config.url.startsWith('https://live.rahisibet') && !config.url.startsWith('https://sockets.rahisibet.com')) {
            if(authToken && Auth.isLoggedIn()) {
                config.headers.Authorization = 'Bearer ' + authToken;
            }
        }

        config.headers.Accept = 'application/json';
        config.headers.ContentType = 'application/json';
        return config;
    }, function(err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use((response) => {
            
            return response;
        }, function (error) {
            
        // Do something with response error
        if (error.response.status === 401) {
            store.dispatch("logout_")
        }

        if(error.response.status === 500) {
            return {
                data:[]
            }
        }

        return Promise.reject(error.response.data);
    });

}