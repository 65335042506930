<template>
  <!-- betslip match -->
  <div class="betslip-match-div">
    <span class="" @click="removeSlip(match.id)">
      <i class="icofont-close-circled"></i>
    </span>
    <div class="">
      
      <p>{{ match.home }} - {{ match.away }}</p>

      <template>
        <p v-if="match.market.type == 'prematch'" class="market-selected">{{ marketTranslate(match.market) }} - {{ marketSpecifier(match.market) }}</p>
        <p v-if="match.market.type == 'live' && $i18n.locale == 'en'" class="market-selected">{{ match.market.mname }} - {{ match.market.pick }}</p>
        <p v-if="match.market.type == 'live' && $i18n.locale == 'fr'" class="market-selected">{{ match.market.frname }} - {{ match.market.frpick }}</p>
      </template>

      <span v-if="match.market.type == 'prematch'" class="">{{ $t('betslip.starts') }} {{ format_date(match.scheduled) }}</span>
      <span v-if="match.market.type == 'live'" :class="{'text-orange': match.market.type == 'live'}">Live'</span>
      <span  class="text-orange" v-if="match.market.changed"> - {{ $t('betslip.odds-changed') }}</span>
  
      <span  class="text-orange" v-if="match.market.status == -2"> - {{ $t('betslip.market-deactivated') }}</span>
      <span  class="text-orange" v-if="match.market.status == 3 || match.market.odd == 0"> - {{ $t('betslip.market-suspended') }}</span>


    </div>
    <span :class="{ 'text-danger' : oddschange }">
        {{ parseFloat(match.market.odd).toFixed(2) }}
    </span>
  </div>
</template>
<script>
import BetslipService from "@/services/betslip";
import moment from 'moment';
export default {
  props:['match'],
  data() {
    return {
        oddschange: false
      }
    },
  methods: {
    marketTranslate: function(mkt) {
      if(mkt.mname == "Match Result") 
      {                
          return "1x2"
      }            
      else if( this.$i18n.te("markets." + mkt.id) ) 
      {              
        let str = this.$i18n.t("markets." + mkt.id,"en");
        let fstr = this.$i18n.t("markets." + mkt.id,"fr");
        
        if (this.$i18n.locale != "en") {
          let mt = '';
          if(mkt.mhandicap != 'n/a') {
            mt = fstr + ' ' + mkt.mhandicap ;
          } else {
            mt = fstr;
          }
          return mt.toLowerCase();
        } else {
          let mt = '';
          if(mkt.mhandicap != 'n/a') {
            mt = str + ' ' + mkt.mhandicap ;
          } else {
            mt = str;
          }
          return mt;  
        }              
      } 
      else if(this.$i18n.te("markets." + mkt.key)) 
      {                
        return this.$i18n.t("markets." + mkt.key).toLowerCase()
      }
      else 
      {
        return mkt.mname.toLowerCase()
      }
    },
    marketSpecifier: function (odd) {
      if( this.$i18n.te("markets." + odd.pick.toLowerCase()) )
      {
          return  this.$i18n.t("markets." + odd.oddKey).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
      else if(this.$i18n.te("markets." + odd.oddKey))
      {
          return  this.$i18n.t("markets." + odd.oddKey).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      }
      else
      {
          return odd.pick
      }
    },
    removeSlip(gameid) {
      BetslipService.popBestlip(gameid);
    },
    format_date(value){
      if (value) {
          return moment(String(value)).format('HH:mm - D/MM')
      }
    },
  }
}
</script>
<style scoped>
.market-selected {
  font-size: 11px;
  color: var(--a-grey);
  text-transform: uppercase;
}
</style>