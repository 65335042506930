<template>
  <div class="bottom-nav-section visible-xs visible-sm">
    <nav class="bottom-nav">
      <div class="nav-item">
        <i class="icofont-ui-home"></i>
        <router-link tag="span" to="/">{{ $t('header.sport') }}</router-link>
      </div>
      <div class="nav-item">
        <i class="icofont-play-alt-2"></i>
        <router-link :to=" getLiveLink()" tag="span">
          {{ $t('header.live') }}
          <template v-if="liveCount">
            ({{ liveCount.total }})
          </template>
        </router-link>
      </div>
      <div class="nav-item">
        <router-link tag="span" to="/betslip" class="bets">{{ betcount + sharedBet.length }}</router-link>
      </div>
      <div class="nav-item">
        <i class="icofont-list"></i>
        <router-link tag="span" to="/all-bets">{{ $t('header.betlist') }}</router-link>
      </div>
      <div class="nav-item">
        <i class="icofont-user"></i>
        <router-link tag="span" to="/profile">{{ $t('header.profile') }}</router-link>
      </div>
    </nav>
  </div>
</template>
<script>
import BetslipService from "@/services/betslip";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      betcount:0
    }
  },
  computed:{
    ...mapGetters({
      sharedBet: "sharedBet",
      liveCount:'liveCount',
      liveMenu: 'liveMenu'
    })
  },
  mounted() {
    this.betslip = JSON.parse(localStorage.getItem("betslip"));
    this.betcount = (this.betslip == null) ? 0 : this.betslip.length;
  },
  created () {
    this.betslip_subscription = BetslipService.getBetslip().subscribe( data => {
      this.betslip = data
      this.betcount = (data == null) ? 0 : data.length
    })
  },
  methods:{
    getLiveLink: function() {
      if(this.liveMenu.length > 0) {
        let url = this.liveMenu.find( x => x.id == 1);
        if(url) {
          return '/live/Soccer';
        } else {
          let url = this.liveMenu.find( (x,i) => i==0);
          let str = url.name.replace(/\s/g, '')
          return `/live/${str}`;
        }
      } else {
        return '/live/Soccer';
      }
    }
  },
  beforeDestroy () {
    // unsubscribe to ensure no memory leaks
    this.betslip_subscription.unsubscribe()
  }
}
</script>
<style scoped>
 sup.badge {
    background-color: red !important;
    font-style: italic;
    font-size: 10px;
  }
</style>