<template>   
    <a @click.prevent="changelang" class="nav-item">
        <template v-for="locale in locales">
            <span v-if="$i18n.locale == locale.code" :key="locale.code">
                <span :class="`flag-icon flag-icon-${iso(locale.country.toUpperCase()).code.toLowerCase()} flag-icon-squared`"></span>{{'\xa0'}}{{'\xa0'}} {{locale.name}}
            </span>                     
        </template>
    </a>
</template>

<script>
import { getSupportedLocales } from "@/utils/supported-locales"
import { getCountryISO2 } from "@/utils/utility"

export default {
    name: 'local-switcher',
    data() {
        return {
            publicPath: process.env.BASE_URL,
            locales: getSupportedLocales()
        }
    },
    methods: {
        useLanguage: function(lang) {
            this.$i18n.locale = lang
        },
        iso: function (code) {
            const res = getCountryISO2(code,'ls')
            if(res) {
                return res
            } else {
                return 'DEFAULT'
            }
        },
        checked(lang) {
            return (this.$i18n.locale == lang) ? 'checked' : '';
        },
        changelang: function() {
            this.$swal.fire({
                // title: this.$i18n.t('withdraw.header'),
                html:`
                <label class="radio-inline">
                    <input type="radio" name="lang" id="pr-1" value="${this.locales[0].code}" ${this.checked(this.locales[0].code)} style="margin-top:0">
                    <span class="flag-icon flag-icon-${this.iso(this.locales[0].country.toUpperCase()).code.toLowerCase()} flag-icon-squared"></span>
                    <b>${this.locales[0].name}</b>
                </label>
                <label class="radio-inline">
                    <input type="radio" name="lang" id="pr-2" value="${this.locales[1].code}" ${this.checked(this.locales[1].code)} style="margin-top:0">
                    <span class="flag-icon flag-icon-${this.iso(this.locales[1].country.toUpperCase()).code.toLowerCase()} flag-icon-squared"></span>
                    <b>${this.locales[1].name}</b>
                </label>`,
                showCancelButton: true,
                confirmButtonText: this.$i18n.t('swal.confirm'),
                cancelButtonColor: '#d33',
                cancelButtonText: this.$i18n.t('betslip.cancel'),
                showLoaderOnConfirm: true,
                preConfirm: () => {
                var lang = document.querySelector('input[name="lang"]:checked').value;
                this.$i18n.locale = lang;
                this.$moment.locale(lang);
                }
            });
        } 
    }
  
}
</script>

<style scoped>
    .lang-select {
        padding: 15px;
        background-color: #073b6b;
        border: none;
        font-size: 15px;
        font-weight: bold;
    }

    .lang-btn {
        width: 50%;
        background-color: #27292f;
        border: 1px solid #33363d;
        color: #f9f9f9;
        font-weight: bold;
        cursor: pointer;
        padding: 5px;
    }

    .lang-select-btn {
        background-color: #073b6b;
        border: 1px solid #073b6b;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
    }

    .lang.dropdown {
        margin-top: 7px;
    }

    .lang.dropdown.open button {
        background-color: #073b6b;
        border: 1px solid #073b6b;
        font-size: 16px;
        font-weight: bold;
    }

    .lang.dropdown .dropdown-menu {
        min-width: 60px;
        width: 122px;
        background-color: #145795;
    }

    .lang.dropdown .dropdown-menu>li>a {
        color: #fff;
        background-color: transparent;
        font-size: 16px !important;
        font-weight: bold;
        margin-left: 12px;
    }

    .lang.btn {
        background-color: #073b6b;
        border: 1px solid #073b6b;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
    }
    @media screen and (max-width: 767px) {
        .lang.dropdown .dropdown-menu {
            right: 0;
            left: auto;
        }
        .lang.btn {
            font-size: 13px;
        }
    }
</style>