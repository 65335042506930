import * as types from "../../types"
import BetslipService from "@/services/betslip";
export const mutations = {
    [types.SET_AUTH_USER] (state, payload) { 
        state.authUser = payload 
        state.status = null
    },
    [types.AUTH_REQUEST] (state) { state.status = 'loading'},
    [types.AUTH_ERROR] (state) { state.status = 'error'},
    [types.CLEAR_AUTH_USER] (state) {
        state.authUser = null
        state.status = null
    },
    [types.SET_LEADER] (state, payload) { state.leaderboard = payload },
    [types.SET_USER_BETS] (state, payload) { state.betHistory = payload },
    [types.SET_SINGLE_BET] (state, payload) { 
        const arr = state.betHistory.find( x => x.bet_id == payload.bet_id)
        state.singleBet = arr; //CLEAR_SINGLE_BET_MATCHES
    },
    [types.SET_SINGLE_BET_MATCHES] (state, payload) { 
        state.betMatches = payload;
    },
    [types.SET_MESSAGES] (state,payload) {
        state.messages = payload;
        state.messages = state.messages.sort( (a,b) => a.status - b.status)
    },
    [types.SET_P_MESSAGES] (state,payload) {
        if(Array.isArray(payload)) {
            if(payload.length > 0) {
                payload.map( msg => state.messages.push(msg))
            }
        }
    },
    [types.CLEAR_SINGLE_BET_MATCHES] (state) { 
        state.betMatches = [];
    },
    [types.SET_SHARED_BET] (state,payload) {
        BetslipService.clearBetslip('betslip');
        state.sharedBet = payload
    },
    [types.REMOVE_SHARED_BET] (state,payload = null) {
        if(payload)
        {
            let index = state.sharedBet.findIndex( x => x.match_id == payload)
            state.sharedBet.splice(index, 1);
        } else {
            state.sharedBet = []
        }
    },
    [types.UPDATE_SHARED_BET] (state,payload) {
        let index = state.sharedBet.findIndex( x => x.match_id == payload.match_id);
        state.sharedBet.splice(index, 1);
        state.sharedBet.push(payload);
    },
    [types.SET_SHARED_ERROR] (state,payload) {
        state.sharedBetError = payload;
    },
    [types.MSG_COUNT] (state,payload) {
        state.msg = payload;
    },
    [types.RATES] (state,payload) {
        state.rates = payload
    },
    [types.GEO] (state,payload) {
        state.geo = payload
    }
}