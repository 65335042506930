import Vue from "vue"
import Vuex from "vuex"
import User from "./modules/user/index.js"
import Games from "./modules/fixtures/index.js"
import Casino from "./modules/casino/index.js"

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        User,
        Games,
        Casino
    }
})


