<template>
  <header>
    <div class="topnav">
      <div class="d-flex">
        <!-- <button class="topnav_menu" @click="toggleSidebar">
          <div class="hambager">
            <div class="hambager__bar"></div> 
            <div class="hambager__bar short"></div> 
            <div class="hambager__bar"></div></div>
        </button> -->
        <router-link to="/">
          <img :src="`${publicPath}images/logo1.png`" alt="Logo" class="img-logo">
        </router-link>
      </div>
      <div class="d-flex auth">
        <lang class="visible-xs visible-sm" />
        <template v-if="!loggedIn">
          <router-link to="/login" class="text-orange">{{ $t('header.login') }}</router-link>
          <router-link to="/register" class="btn-reg bg-orange">{{ $t('header.register') }}</router-link>
        </template>
        <template v-else>
          <div class="links">
            <router-link tag="span" to="/all-bets" class="hidden-xs"><i class="icofont-list"></i> {{ $t('header.betlist') }}</router-link>
            <router-link tag="span" to="/profile" class="hidden-xs"><i class="icofont-user-alt-2"></i> {{ $t('header.profile') }}</router-link>
            <router-link tag="span" to="/profile" class="hidden-xs refresh-balance" v-if="user.iso != 'BI'"> {{ $t('header.deposit') }}</router-link>
            <span v-if="user.iso == 'BI'" class="refresh-balance" @click ="deposit">{{ $t('header.deposit') }}</span>
          </div>
          <modal name="deposit" :width="300" height="auto" :adaptive="true">
            <h5 class="text-center dep">{{ $t('deposit.header') }}</h5>
            <div class="text-center">
                <label class="radio-inline"><input type="radio" name="optradio" @change="provider" value="lumicash" :checked="provider_type=='lumicash'">Lumicash</label>
            </div>
            <div class="error-div ">
                <span v-if="amount < 200" class="text-danger">{{  $t('deposit.min-length-error') }} &nbsp;</span>                     
                <span v-if="voucher === '' && lumicash" class="text-danger">{{ $t('deposit.voucher') }}</span> 
                <span v-if="voucher === '' && lumicash" class="text-danger">({{ $t('lumicash.li-8')}})</span>
                <span v-if="amount > 1000000 && lumicash && voucher !== '' " class="text-danger">{{ $t('swal.lumicash-max-deposit') }}</span>
                <span v-if="amount >= 200  && !lumicash" class="text-success">{{ message }}</span>
                <span v-if="amount >= 200 && amount <=1000000  && lumicash && voucher !== ''" class="text-success">{{ message }}</span>
            </div>
            <div class="deposit-badge">
              <span class="badge bg-blue" @click="updateAmount(500)">+500</span>
              <span class="badge bg-blue" @click="updateAmount(1000)">+1000</span>
              <span class="badge bg-blue" @click="updateAmount(2000)">+2000</span>
              <span class="badge bg-blue" @click="updateAmount(10000)">+10000</span>
              <span class="badge bg-blue" @click="updateAmount(20000)">+20000</span>
            </div>
            <div class="form-group pad" >
                <input type="text" class="form-control lumicash" id="voucher" name="voucher" v-model="voucher" v-if="lumicash" :placeholder="$i18n.t('lumicash.li-1-1')" required>                    
                <input type="text" class="form-control" id="amount" name="amount" v-model="amount" placeholder="200">
            </div>
            <div class="text-center flexx">
                <button class="btn btn-primary btn-sm" :disabled="amount < 200  && provider_type === 'pesaflash'" v-if="provider_type=='pesaflash'" @click="makeDeposit_">{{ $t('deposit.header') }}</button>
                <button class="btn btn-primary btn-sm" :disabled="amount < 200 || amount > 1000000 || (voucher === '' && lumicash)" v-if="provider_type=='lumicash'" @click="makeDeposit_">{{ $t('header.deposit') }}</button>
                <button class="btn btn-danger btn-sm" @click="cancelDeposit_">{{ $t('betslip.cancel') }}</button>
            </div>
                
          </modal>
        </template>
      </div>        
    </div>
    <sidebar :show="show" @emitToggle="toggleSidebar"></sidebar>
    <navitem></navitem>
  </header>
</template>
<script>
  import sidebar from './sidebar.vue';
  import navitem from './nav.vue';
  import {mapGetters,mapActions} from "vuex";
  import Auth from "@/services/auth.js";
  import lang from '@/components/sections/nav-sections/locale-switcher.vue';

  export default {
    components: {
      sidebar,
      navitem,
      lang,
    },
    data() {
      return {
        show:false,
        publicPath: process.env.BASE_URL,
        shows: false,
        chk:false,
        lumicash: true,
        provider_type: "lumicash",
        voucher: "",
        amount: 200,
        message: this.$i18n.t('deposit.submit'),
        interval:null
      }
    },
    methods: {
      ...mapActions({
        auth_logout: 'logout_user',
        refresh_balance: 'get_refresh_balance',
        set_casino: 'set_casino'
      }),
      toggleSidebar: function() {
        this.show = !this.show
      },
      emitToggle: function(arg) {
        this.show = !arg
      },
      logout: function() {
        this.$swal({
          icon:'warning',
          title: this.$i18n.t('header.logout'),        
          showCancelButton:true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$i18n.t('swal.cancel'),
          confirmButtonText: this.$i18n.t('header.logout')
        }).then((result) => {
          if(result.value) {
            this.auth_logout().then( () => {
              if (this.$route.path != '/') {
                  this.$router.push("/");
              }
            })   
          }
        })
      },  
      refreshBalance: async function () {
        await this.refresh_balance()
      },
      provider: function(e) {
        var data = e.target.value;
        this.lumicash = (data === "lumicash") ? true : false;
        this.provider_type = data
      },
      deposit: function() {
        this.$modal.show('deposit')
      },
      makeDeposit_: function () {
        this.message = this.$i18n.t('withdraw.li-5')
        let deposit = {};

        deposit.amount = this.amount;
        deposit.phone = this.user.phone;
        deposit.provider = this.provider_type;
        deposit.voucher = (this.provider_type === "lumicash") ? this.voucher : "";

        let provider__ = this.provider_type;

        Auth.makeDeposit(deposit).then(data => {
          // reset defaults
          this.$modal.hide("deposit")
          this.provider_type = 'lumicash'
          this.amount = 200
          this.voucher = ''
          this.message = this.$i18n.t('deposit.submit')

          if (data.success) {
              if(data.status != 'failed') {
                  this.refresh_balance()
                  if(provider__ == 'lumicash') {
                      this.$swal.fire(
                          data.message,   
                          '',                                    
                          'success'
                      );
                  } else {
                      this.$swal.fire(
                          this.$i18n.t('deposit.pesaflash-dial'),   
                          '',                                    
                          'success'
                      );
                  }
              } else {
                  this.$swal.fire(
                      data.message,   
                      'Oops...',                                    
                      'error'
                  );
              }
          }
          else if (data.statusCode == 301) {
              this.$swal.fire(
                  data.message,
                  'Oops...',
                  'info'
              );
          }
          else {
              this.$swal.fire(
                  data.message,
                  '',
                  'info'
              );
          }
        }).catch( err => console.log(err) )
      },
      cancelDeposit_: function () {
        this.$modal.hide("deposit")
      },
      updateAmount(arg) {
        this.amount = arg;
      },
      async slipsCheck() {
        await this.$store.dispatch("fetch_live_slips");
      }
    },
    computed:{
      ...mapGetters({
        account: 'account',
        user:'user',
        loggedIn: 'isAuthenticated',
        casino:'type'
      }),
      isIdle () {
        return this.$store.state.idleVue.isIdle;
      }
    }, 
    watch: {
      '$route': async function(url) {
        if(url.name != 'live') {
          this.slipsCheck();
          this.interval = setInterval(() => {
            this.slipsCheck();
          }, 5000);
        }
        if(url.name == 'live') {
          clearInterval(this.interval);
        }
      }
    },
    mounted() {
      Auth.getRates().then( res => this.$store.dispatch('updateRates',res)).catch( (err) => console.log(err))
      Auth.detectLoc().then( res => {
        if(res.status == 200) {
          this.$store.dispatch('updateGeo',res.data)
        }
      });
    }
  }
</script>
<style scoped>

  .d-flex .links {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .d-flex .links span {
    display: inline-block;
    padding: 3px 8px;
    font-weight: 400;
    cursor: pointer;
  }
  
  .vm--container .dep {
      font-weight: bold;
      color: #595959;
      font-size: 1.5em;
  }
 
  .vm--container .lumicash {
      margin-bottom: 10px;
  }
 
  .vm--container .flexx .btn {
      flex: 1 1 0;
  }
  
  
  .links i {
    margin-right: 0px;
  }
  .deposit-badge {
    padding: 10px 5px 5px 20px;
  }
  .deposit-badge .badge {
    display: inline-block;
    padding: 6px;
    margin-right: 5px;
    cursor:pointer
  }
</style>