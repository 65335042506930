<template>
	<div id="app">
		<template v-if="!avaitrixDemo">
			<div class="no-wrapper">
				<vue-progress-bar></vue-progress-bar>
				<NewHeader />
				<router-view></router-view>
				<Footer/>
			</div>
		</template>
		<template v-else>
			<div class="aviatrix-wrapper">
				<!-- <NewHeader /> -->
				<div class="go-back"  @click="$router.go(-1)">
					<span class="">
						<i class="icofont-arrow-left"></i>
					</span>
					<span>{{ $t('betslip.go-back') }}</span>
				</div>
				<router-view></router-view>
			</div>
		</template>
	</div>
</template>

<script>

import NewHeader from "@/components/New/common/header";
import Footer from "@/components/sections/Footer";
require('moment/locale/fr');

export default {
	name: 'App',
	components: {
		NewHeader,
		Footer
	},
	data() {
		return {
			show : false,
			aviatrix:['aviatrix_renderer','demo_aviatrix_renderer','slot_renderer','virtual_renderer','live_renderer','demo_renderer','demo_jetx_renderer','live_jetx_renderer'],
		}
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		avaitrixDemo() {
			if( screen.width <= 760 && this.aviatrix.includes(this.$route.name)) {
				return true;
			} else {
				return false
			}
		},
		screenCasino() {
			console.log(this.casino.includes(this.$route.name));
			if( screen.width <= 760 && this.casino.includes(this.$route.name)) {
				return true;
			} else {
				return false
			}
		}
	},
	mounted() {
		this.$moment.locale(this.$i18n.locale);
	}
}
</script>

<style scoped>
	.go-back {
		display: flex;
		padding: 8px 8px;
	}
	.go-back span {
		font-weight: 500;
	}
	@media screen and (max-width: 767px) {
		.section.content {
			padding: 0px;
		}
	}
</style>
