<template>
    <div class="alert fade in" :class="[type]">
        <span>{{ message }}</span>
    </div>
</template>

<script>
export default {
  name: 'alerts',
  props: {
    type: String,
    message: String
  }
}
</script>

<style scoped>

</style>