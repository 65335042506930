import supportedLocales from "@/config/supported-locales"

export function getSupportedLocales() {
  
  let annotatedLocales = []

  for (const code of Object.keys(supportedLocales.lang)) {
    
    annotatedLocales.push({
      code,
      name: supportedLocales.lang[code],
      country:supportedLocales.code[code]
    })
  }

  return annotatedLocales
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales.lang).includes(locale)
}