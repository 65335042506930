export const SET_AUTH_USER = "SET_AUTH_USER"
export const SET_AUTH_ERROR = "SET_AUTH_ERROR"
export const CLEAR_AUTH_USER = "CLEAR_AUTH_USER"
export const AUTH_REQUEST = "AUTH_REQUEST"
export const AUTH_ERROR = "AUTH_ERROR"

export const SET_SLIDES = "SET_SLIDES"
export const SET_LEADER = "SET_LEADER"
export const SET_MEGAJACKPOT_SLIDES = "SET_MEGAJACKPOT_SLIDES"

// Jackpots
export const GET_MEGA_JACKPOT_GAMES = "GET_MEGA_JACKPOT_GAMES"
export const GET_MINI_JACKPOT_GAMES = "GET_MINI_JACKPOT_GAMES"

// Feeds
export const SET_GAMES = "SET_GAMES"
export const SET_PAGINATED_GAMES = "SET_PAGINATED_GAMES"
export const SET_META = "SET_META"
export const GET_MARKETS = "GET_MARKETS"
export const SET_MARKETS = "SET_MARKETS"
export const GET_TOURNAMENT_GAMES = "GET_TOURNAMENT_GAMES"

//Single match
export const SET_SINGLE_MATCH = "SET_SINGLE_MATCH"
export const UPDATE_SINGLE_MATCH = "UPDATE_SINGLE_MATCH"

// Sidebar
export const GET_SIDEBAR_MENU = "GET_SIDEBAR_MENU"
export const GET_TOP5_MENU = "GET_TOP5_MENU"


//Betslip
export const UPDATE_NORMAL_BETSLIP = "UPDATE_NORMAL_BETSLIP"
export const UPDATE_JACKPOT_BETSLIP = "UPDATE_JACKPOT_BETSLIP"
export const SET_USER_BETS = "SET_USER_BETS"
export const SET_SINGLE_BET = "SET_SINGLE_BET"
export const SET_SINGLE_BET_MATCHES = "SET_SINGLE_BET_MATCHES"
export const CLEAR_SINGLE_BET_MATCHES = "CLEAR_SINGLE_BET_MATCHES"
export const SET_SHARED_BET = "SET_SHARED_BET"
export const REMOVE_SHARED_BET = "REMOVE_SHARED_BET"
export const UPDATE_SHARED_BET = "UPDATE_SHARED_BET"
export const SET_SHARED_ERROR = "SET_SHARED_ERROR"
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_P_MESSAGES = "SET_P_MESSAGES"

// Casino
export const SET_CASINO = "SET_CASINO"
export const SET_CASINO_TYPE = "SET_CASINO_TYPE"
export const SET_CASINO_LIVE_TYPE = "SET_CASINO_LIVE_TYPE"
export const SET_CASINO_GAMES = "SET_CASINO_GAMES"
export const CASINO_GAMES_ERROR = "CASINO_GAMES_ERROR"
export const SET_LOBBY_TABLE = "SET_LOBBY_TABLE"
export const RESET_LOADER = "RESET_LOADER"
export const SET_CASINO_BETS = "SET_CASINO_BETS"
export const MSG_COUNT = "MSG_COUNT"
export const RATES = "RATES"
export const GEO = "GEO"

//Live
export const SET_LIVE = "SET_LIVE"
export const SET_ROOM = "SET_ROOM"
export const SET_ID = "SET_ID"
export const SET_SINGLE_LIVE = "SET_SINGLE_LIVE"
export const UPDATE_LIVE_BETSLIP = "UPDATE_LIVE_BETSLIP"
export const UPDATE_LIVE_MATCH = "UPDATE_LIVE_MATCH"
export const RESET_LIVE = "RESET_LIVE"
export const LIVE_COUNT = "LIVE_COUNT"
export const LIVE_MENU = "LIVE_MENU"
export const LIVE_SPORT = "LIVE_SPORT"
export const LIVE_MATCH = "LIVE_MATCH"
export const LIVE_BETSTOP = "LIVE_BETSTOP"
export const LIVE_ENDED = "LIVE_ENDED"
export const UPDATE_LIVE_SLIPS = "UPDATE_LIVE_SLIPS"
