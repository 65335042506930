import axios from "axios"
import {
    LOGIN_URL,
    LOGOUT_URL,
    REGISTER_URL,
    VERIFICATION_URL,
    FORGOT_PASSWORD_URL,
    RESET_PASSWORD_URL,
    JIBUPAY_DEPOSIT,
    JIBUPAY_WITHDRAWAL,
    PROFILE_URL,
    UPDATE_PASSWORD,
    INVITE_URL,
    USER_DETAILS_URL,
    REBET_URL,
    MESSAGES_URL,
    MESSAGE_URL,
    VIRTUALPAY_URL,
    VIRTUALPAY_POP,
    RATES_URL
} from "./api_endpoints"

export default class Auth {

    static login(payload){
        
        // you can use this old skul way or use async await
        return new Promise((resolve,reject)=>{
            axios.post(LOGIN_URL,payload).then(res => {
                resolve(res.data)                
            }).catch(err=>{
                reject(err)
            })
        })
       
    }

    static logout() {     
        return new Promise((resolve,reject)=>{
            axios.post(LOGOUT_URL,{}).then(res => {
                resolve(res.data)                
            }).catch(err=> {
                reject(err)
            })
        })
    }

    static isLoggedIn() {
        let user = JSON.parse(localStorage.getItem("authUser"))
        
        if (user !=null && user.token) {
            return true;
        }
        else {
            return false;
        }
    }

    static getAuthorizationToken() {
        let user = JSON.parse(localStorage.getItem("authUser"))
        if(user) {
            return user.token;
        } else {
            return null
        }
    }

    static getAuthUser() {
        let user = JSON.parse(localStorage.getItem("authUser"))
        if(user) {
            return user;
        } else {
            return null
        }
    }

    static getTokenExpirationDate() {
        const user = JSON.parse(localStorage.getItem('authUser'))
        return user.expires_at
    }

    static async register(user) {
        const games = await axios.post(REGISTER_URL,{
            "phone": user.phone,
            "iso": user.iso,
            "password": user.password,
            "password_confirmation": user.confirmPassword,
        });      
        return games.data;
    }

    static async rebet(payload) {
        const games = await axios.post(`${REBET_URL}/${payload}`,{bet_id:payload});      
        return games.data;
    }

    static async verification(user) {
        const games = await axios.post(VERIFICATION_URL,{
            "phone": user.phone, 
            "otp": user.verificationCode
        });      
        return games.data;
    }

    static async forgot_password(user) {
        const games = await axios.post(FORGOT_PASSWORD_URL,{
            "phone": user.phone 
        });      
        return games.data;
    }

    static async reset_password(user) {
        const games = await axios.post(RESET_PASSWORD_URL,{
            "otp": user.resetCode, 
            "phone": user.phone, 
            "password": user.password, 
            "password_confirmation": user.confirmPassword
        });      
        return games.data;
    }

    static async makeDeposit(deposit) {
        const res = await axios.post(JIBUPAY_DEPOSIT,{
            "phone" : deposit.phone,
            "amount" : deposit.amount,
            "provider" : deposit.provider,
            "voucher" : deposit.voucher
        });      
        return res.data;
    }
    
    static async makeWithdrawal(deposit) {
        let obj = {}
        if (deposit.provider == 'astropay') {
            obj = {
                phone: deposit.phone,
                amount: deposit.amount,
                provider: deposit.provider,
                country: deposit.country,
                currency: deposit.currency,
            }
        } else {
            obj = {
                phone: deposit.phone,
                amount: deposit.amount,
                provider: deposit.provider
            }
        }
        const res = await axios.post(JIBUPAY_WITHDRAWAL,obj);      
        return res.data;
    }

    static async updateProfile(profile) {
        const res = await axios.post(PROFILE_URL,{
            'name':profile.name,
            "surname":profile.surname
        });      
        return res.data;
    }

    static async updatePassword(password_obj) {
        const res = await axios.post(UPDATE_PASSWORD,{
            "password":password_obj.password
        });      
        return res.data;
    }

    static async getUserdetails() {
        const res = await axios.get(USER_DETAILS_URL)
        return res.data
    }

    static async getAllMessages(payload) {
        let auth = JSON.parse(localStorage.getItem("authUser")) 
        if(auth) {
            const url = MESSAGES_URL + auth.user.id;
            const res = await axios.get(url,{ params : payload.post});
            return res.data;
        } else {
            return null;
        }
    }
    static async getSingleMessage(id) {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        if(auth) {
            const url = MESSAGE_URL + id;
            const res = await axios.get(url);
            return res.data
        } else {
            return null;
        }
    }

    static async getAstropayUrl(option,amount) {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        if(auth) {
            const res = await axios.post(JIBUPAY_DEPOSIT,{
                amount: amount,
                currency: option.currency.toLowerCase(),
                country: option.country,
                userId: auth.user.id,
                provider: option.provider,
                phone: option.phone,
            });   

            return res.data;
        }
    }
    
    
    static async getVirtualUrl(option) {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        if(auth) {
            const res = await axios.post(VIRTUALPAY_URL,{
                amount: option.amount,
                currency: option.currency.toLowerCase(),
                mobile: option.phone
            });   

            return res.data;
        }
    }
    
    static async inviteFriend( phone) {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        if(auth) { 
            try {
                const res = await axios.post(INVITE_URL,{
                    msisdn: phone,
                });   
    
                return res.data;
            } catch (error) {
                return error;
            }
            
        }
    }

    static async postVirtualPay(post) {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        if(auth) {
            const res = await axios.post(VIRTUALPAY_POP,{
                MID: post.mid,
                API_KEY: post.apikey,
                PRIVATE_KEY: post.pkey,
                REDIRECT_URL: post.rurl,
                NOTIFICATION_URL: post.nurl,
                REQUESTID: post.requestId,
                MOBILE: post.mobile,
                COUNTRY: post.country,
                AMOUNT: post.amount,
                CHECKSUM: post.cheksum
            });   

            return res.data;
        }
    }

    static async getRates() {
        const res = await axios.get(RATES_URL);   
        return res.data;
    }

    static async detectLoc() {
        let loaction = JSON.parse(localStorage.getItem("location"));
        if(loaction) {
            return loaction;
        } else {
            const params = { apiKey: '7f51cb0155e5442eb2c54347aa49da82' }
            const res = await axios.get('https://api.ipgeolocation.io/ipgeo',  {params:params});
            localStorage.setItem('location',JSON.stringify(res));

            return res;
        }
    }
}