
import  *  as types from "../../types"
import BetslipService from "@/services/betslip"
import Auth from "@/services/auth"


export const actions = {
    async auth_user({commit},payload) {
        commit(types.AUTH_REQUEST)
        await Auth.login(payload).then( 
            res => {
                if( res.success) {                    
                    localStorage.setItem('authUser', JSON.stringify(res.data))
                    commit(types.SET_AUTH_USER,res.data)
                } else {
                    commit(types.AUTH_ERROR)
                }
            },
            () => commit(types.AUTH_ERROR)
        )
    },
    async logout_user({commit}) {
        await Auth.logout().then( res => {
            if( res && res.message == "You've been signed out!") {
                localStorage.removeItem('authUser');
                localStorage.removeItem('jetxtoken');
                commit(types.CLEAR_AUTH_USER)
            }
        })
    },
    logout_({commit}) {
        return new Promise((resolve) => {
            localStorage.removeItem('authUser')
            commit(types.CLEAR_AUTH_USER)
            resolve()
        })
        
    },
    async get_user_bets({commit},payload) {
        const bets = await BetslipService.getBetHistory(payload)
        commit(types.SET_USER_BETS,bets.data)
        if(bets.data) { 
            commit(types.SET_USER_BETS,bets.data)
            commit(types.SET_META,{page:bets.nextPage, current:bets.currentPage })
        } 
    },
    async get_user_bets_by_date({commit},payload) {
        const bets = await BetslipService.getBetByDateRange(payload)
        
        commit(types.SET_USER_BETS,bets.data)
        commit(types.SET_META,{date:payload.date,page:bets.nextPage, current:bets.currentPage })
    },
    async get_single_bet({commit},payload) {
        const bet = await BetslipService.getBetById(payload);
        if(bet) {
            commit(types.SET_SINGLE_BET,payload);
            commit(types.SET_SINGLE_BET_MATCHES,bet);
        } else {
            commit(types.SET_SINGLE_BET,payload);
            commit(types.SET_SINGLE_BET_MATCHES,[]);
        }
       
    },
    async clear_single_betmatches({commit}) {
        commit(types.CLEAR_SINGLE_BET_MATCHES);
    },
    async set_messages({commit},payload) {
        const res = await Auth.getAllMessages(payload);
        if(res.success) {
            if(payload.type == 'new') {
                commit(types.SET_MESSAGES,res.data.data);
            } else {
                commit(types.SET_P_MESSAGES,res.data.data);
            }
            commit(types.SET_META,{ page:res.data.nextPage, current:res.data.currentPage })
        } else {
            if(payload.type == 'new') {
                commit(types.SET_MESSAGES,[]);
            } else {
                commit(types.SET_P_MESSAGES,[]);
            }
        }
    },
    async get_refresh_balance({commit}) {
        const user = await Auth.getUserdetails()
        let authUser = JSON.parse(localStorage.getItem("authUser"))

        authUser.user = user.user
        localStorage.setItem('authUser', JSON.stringify(authUser))
        commit(types.SET_AUTH_USER,authUser)
    },
    async fetch_pagination_bets({commit},payload) {
        const bets = await BetslipService.getPagination(payload)
        bets.meta.type = payload.type
        commit(types.SET_USER_BETS,bets.data)
        commit(types.SET_META,bets.meta)
    },
    async fetch_shared_bet({commit},code) {
        const res = await BetslipService.getSharedBet(code)
        if(res.data) {
            commit(types.SET_SHARED_BET,JSON.parse(res.data.games));    
            commit(types.SET_SHARED_ERROR,null)
        } else {
            commit(types.SET_SHARED_ERROR,res.message)
        }
    },
    removeSharedBets({commit},payload = null) {
        if(payload) {
            commit(types.REMOVE_SHARED_BET,payload);
        } else {
            commit(types.REMOVE_SHARED_BET);
        }
    },
    updateSharedbets({commit},payload) {
        commit(types.UPDATE_SHARED_BET,payload);
    },
    updateMessage({commit},payload) {
        commit(types.MSG_COUNT,payload)
    },
    updateRates({commit},payload) {
        commit(types.RATES,payload);
    },
    updateGeo({commit},payload) {
        commit(types.GEO,payload);
    }
}