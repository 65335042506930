<template>
    <!-- footer -->
    <div v-frag>
        <footer class=" hidden-xs hidden-sm" id="footer">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-xs-6 col-sm-3 col-lg-3">
                        <h5 v-if="geo && geo.country_code2 != 'BI'" class="line3 center standart-h4title"><span>{{ $t('footer.contact-title1')  }}</span></h5>
                        <h5 v-if="geo && geo.country_code2 == 'BI'" class="line3 center standart-h4title"><span>{{ $t('footer.contact-title')  }}</span></h5>

                        <p v-if="geo && geo.country_code2 == 'BI'">{{ $t('footer.legal-notice') }}</p>
                        <p v-if="geo && geo.country_code2 != 'BI'">{{ $t('footer.global') }}</p>
                        <p>{{ $t('footer.cookies') }}</p>

                        <h5 class="line3 center standart-h4title ft-top"><span>{{ $t('header.sport') }}</span></h5>
                        <ul class="footer-links list-unstyled">
                            <li><router-link to = "/">{{ $t('header.sport') }}</router-link></li>
                            <li><router-link  to="/live" class="nav-item">{{ $t('header.live') }}</router-link></li>
                            <li> <router-link  to="/results" class="nav-item">{{ $t('header.results') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="col-xs-6 col-sm-3 col-lg-3">
                        <h5 class="line3 center standart-h4title"><span>{{ $t('footer.legal') }}</span></h5>
                        <p><span class='age18'>{{ $t('footer.legal-age')  }}</span>{{ $t('footer.legal-age-notice') }}</p>
                        <p>{{ $t('footer.legal-warning') }}</p>
                        <h5 class="line3 center standart-h4title ft-top"><span>{{ $t('casino.casino') }}</span></h5>
                        <ul class="footer-links list-unstyled">
                            <li><router-link to = "/aviatrix" class="nav-item">{{ $t('casino.aviator') }}</router-link></li>
                            <li> <router-link to = "/casino-home" class="nav-item">{{ $t('casino.casino') }}</router-link></li>
                            <li> <router-link to = "/jetx" class="nav-item">JetX</router-link></li>
                        </ul>
                    </div>
                    <div class="col-xs-6 col-sm-3 col-lg-3">
                        <h5 class="line3 center standart-h4title"><span>{{ $t('footer.contact') }}</span></h5>
                        <p><i class="fa fa-question-circle" aria-hidden="true"></i> {{ $t('footer.how-to-play1') }}</p>

                        <p v-if="geo && geo.country_code2 == 'BI'"><i class="fa fa-phone" aria-hidden="true"></i> {{ $t('footer.service-client') }}</p>
                        <p v-if="geo && geo.country_code2 == 'BI'"><i class="fa fa-whatsapp" aria-hidden="true"></i> {{ $t('footer.whatsapp') }}</p>
                        <p v-if="geo && geo.country_code2 == 'BI'">{{ $t('footer.contact-email') }}</p>

                        <p v-if="geo && geo.country_code2 != 'BI'"><i class="fa fa-phone" aria-hidden="true"></i> {{ $t('footer.int_cc') }}</p>
                        <p v-if="geo && geo.country_code2 != 'BI'"><i class="fa fa-whatsapp" aria-hidden="true"></i> {{ $t('footer.int_w') }}</p>
                        <p v-if="geo && geo.country_code2 != 'BI'">{{ $t('footer.int_e') }}</p>

                        <div class="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=100095295397092" target="_blank"><img src="/images/facebook.svg" alt="facebook"></a>
                            <a href="https://twitter.com/Rahisibet" target="_blank"><img src="/images/twitter.png" alt="twitter"></a>
                            <a href="https://twitter.com/Rahisibet" target="_blank"><img src="/images/instagram.svg" alt="instagram"></a>
                        </div>
                        <!-- <p>{{ $t('footer.contact-phone-2') }}</p> -->
                    </div>
                    <div class="col-xs-6 col-sm-3 col-lg-3 ">
                        <h5 class="line3 center standart-h4title "><span>{{ $t('header.payments') }}</span></h5>
                        <div class="large-img">
                            <img src="/images/astropay.webp" alt="Lumicash" title="Lumicash" class=""/>
                            <img v-if="chk == 'BI'" src="/images/lumi.png" alt="Lumicash" title="Lumicash" class="" style="margin-top: -3px;"/>
                            <img v-if="chk == 'ZM' || chk == 'TZ' || chk == 'GH' || chk == 'CM' || chk == 'KE'" :src="`${publicPath}images/logos/mastercard.png`" alt="Logo" class="img-logo">
                            <img v-if="chk == 'ZM' || chk == 'TZ' || chk == 'GH' || chk == 'CM' || chk == 'KE'" :src="`${publicPath}images/logos/visa.png`" alt="Logo" class="img-logo">

                            <template v-if="chk == 'BJ' || chk == 'CD'">
                                <img v-if="chk == 'CM' || chk == 'BJ' || chk == 'ZM' || chk == 'CI' || chk == 'GH'" :src="`${publicPath}images/logos/mtn.png`" alt="Logo" class="img-logo">   
                                <img v-if="chk == 'TZ' || chk == 'GH' || chk == 'ZM' || chk == 'CD'" :src="`${publicPath}images/logos/airtel.png`" alt="Logo" class="img-logo" > 
                            </template>
                        </div>
                        <div class="payments country" style="margin-bottom: 10px;" v-if="chk != 'BJ' && chk != 'CD'"> 
                            <img v-if="chk == 'TZ' || chk == 'GH' || chk == 'ZM' || chk == 'CD'" :src="`${publicPath}images/logos/airtel.png`" alt="Logo" class="img-logo" >
                            <img v-if="chk == 'CI'" :src="`${publicPath}images/logos/moov.png`" alt="Logo" class="img-logo" >
                            <img v-if="chk == 'CM' || chk == 'BJ' || chk == 'ZM' || chk == 'CI' || chk == 'GH'" :src="`${publicPath}images/logos/mtn.png`" alt="Logo" class="img-logo">
                            <img v-if="chk == 'CI' || chk == 'CM'" :src="`${publicPath}images/logos/orange.png`" alt="Logo" class="img-logo"> 
                            <img v-if="chk == 'TZ'" :src="`${publicPath}images/logos/tigo.png`" alt="Logo" class="img-logo">
                            <img v-if="chk == 'TZ' || chk == 'GH'" :src="`${publicPath}images/logos/vodacom.png`" alt="Logo" class="img-logo">
                            <img v-if="chk == 'ZM'" :src="`${publicPath}images/logos/zamtel.png`" alt="Logo" class="img-logo">
                        </div>
                        
                        <div class="payments" v-if="chk != 'UG' && chk != 'RW'">
                            <img src="/images/bitcoin.png" alt="Bitcoin" class="img-crypto"/>
                            <img src="/images/ethereum.png" alt="Ethereum" class="img-crypto"/>
                            <img src="/images/litecoin.png" alt="Litecoin" class="img-crypto"/>
                            <img src="/images/ripple.png" alt="Ripple" class="img-crypto"/>
                            <img src="/images/tether.svg" alt="USDT-ERC20" class="img-crypto"/>
                        </div>
                        <h5 class="line3 center standart-h4title"><span>{{ $t('footer.support') }}</span></h5>
                        <ul class="footer-links list-unstyled">
                            <li><router-link to="/terms-and-conditions">{{ $t('footer.terms-and-conditions') }}</router-link></li>
                            <li><router-link to="/responsible-gaming">{{ $t('footer.responsible-gaming') }}</router-link></li>
                            <li><router-link to="/aml-policy">{{ $t('aml-policy.link') }}</router-link></li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="row text-center">
                    <div class="col-md-12">
                        <p> © {{ $t(currentDate) }} {{ $t('footer.copyright') }}: <a href="/"><strong style="color: #f7941e;"> {{ $t('footer.contact-title') }}</strong></a> | {{ $t('footer.rights') }}</p>
                    </div>
                </div>
            </div>
            <!-- CONTAINER -->
        </footer>
        <NewFooter />
        <!-- /FOOTER-->
        <!-- end footer -->
    </div>
</template>

<script>
import NewFooter from "@/components/New/common/footer";
import { mapGetters } from "vuex";
export default {
    name: 'Footer',
    components: {
        NewFooter
    },
    data() {
        return {
            currentDate : new Date().getFullYear(),
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        ...mapGetters({
            geo:'geo',
            user_:'user',
        }),
        chk() {
            if(this.user_ && this.user_.iso) {
                return this.user_.iso;
            }
            else if(this.geo && this.geo.country_code2) {
                return this.geo.country_code2;
            } else {
                return "KE";
            }
        }
    },
}
</script>

<style scoped>
    footer .standart-h4title {
        text-transform: uppercase;
        border-bottom: 1px solid #607d8b;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .footer .ft-top {
        margin-top:20px ;
    }

    footer .whatsapp {
        background-color: green;
        padding: 5px 4px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 10px;
        text-align: -webkit-center;
        font-size: 13px;
    }

    footer .call {
        background-color: #f7941e;
        padding: 5px 4px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 10px;
        text-align: -webkit-center;
        font-size: 13px;
    }
    footer hr {
        border-top: 1px solid #607d8b;
    }
    footer .payments {
        display: flex;
        gap:4px;
    }
    footer .payments img {
        width: 30px;
    }
    footer .payments.country img {
        width: 70px;
    }
    footer .payments img.astropay {
        border-radius: 50%;
    }
    footer .lumicash img {
        max-width: 200px;
    }
    .large-img {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }
    .large-img  img{
       max-height: 25px;
       border-radius: 3px;

    }
    .social-icons img{
        width: 30px;
    }
</style>
