import * as types from "../../types"

export const mutations = {
    [types.SET_CASINO](state, payload) { state.casino = payload },
    [types.SET_CASINO_TYPE](state, payload) { state.casino_type = payload },
    [types.SET_CASINO_LIVE_TYPE](state, payload) { state.tableType = payload },
    [types.SET_CASINO_BETS](state, payload) { state.bets = payload },
    [types.RESET_LOADER](state,payload) {
        switch(payload){
            case "LIVE":
                state.loadingLive = false
                break
            case "VIRTUALS":
                state.loadingVirtuals = false
                break
            default:
                state.loadingSlots = false
                break
                
        }
        
    },
   

    [types.SET_CASINO_GAMES](state, payload) {
        switch (payload.type) {
            case "slots":
                state.casinoGames = payload.games
                break
            case "live":
                state.liveCasinoGames = payload.games
                break
            case "virtuals":
                state.virtualGames = payload.games
                break
            default:
                break
        }

        state.loadingCasinos = false
    },
    [types.SET_LOBBY_TABLE](state, payload) {
        switch (payload.table) {
            // case "POPULAR":
            //     state.lobby.popular = payload.data
            //     break;
            default:
                break
        }
    }
}