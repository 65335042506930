<template>
    <!-- betslip match -->
    <div class="betslip-match-div">
      <span class="" @click="removeSlip(match.match_id)">
        <i class="icofont-close-circled"></i>
      </span>
      <div class="">
        <p>{{ match.name }}</p>
        <p class="market-selected">{{ match.market }} • {{ match.pick }}</p>
        <span class="">Starts {{ match.scheduled | moment('D/MM,HH:mm') }}</span>
      </div>
      <span>{{ parseFloat(match.odd).toFixed(2) }}</span>
      <!-- <span class="text-orange">{{ parseFloat(odd_values.odds).toFixed(2) }}</span> -->
    </div>
  </template>
  <script>
//   import BetslipService from "@/services/betslip";
  
  export default {
    props:['match','betslip'],
    data() {
      return {
          oddschange: false
        }
      },
    methods: {
      removeSlip(gameid) {
        this.$store.dispatch('removeSharedBets',gameid);
      },
    }
  }
  </script>
  <style scoped>
  .market-selected {
    font-size: 11px;
    color: var(--a-grey);
    text-transform: uppercase;
  }
  </style>