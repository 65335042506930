export const state = {
    authUser: JSON.parse(localStorage.getItem('authUser')) || null,
    status: null,
    betHistory: null,
    singleBet:null,
    leaderboard:null,
    sharedBet:[],
    sharedBetError:null,
    msg:0,
    rates: null,
    geo:null,
    betMatches:[],
    messages:[]
}