import * as types from "../../types"
import { getSport } from "@/utils/utility";
import BetslipService from "@/services/betslip";

export const mutations = {
    [types.SET_SLIDES] (state,payload) { state.slides = payload },

    [types.SET_MEGAJACKPOT_SLIDES] (state,payload) { state.jackpot_slides = payload },

    [types.SET_GAMES] (state,payload) { 

        let betslip = JSON.parse(localStorage.getItem("betslip"));
        let regex = /[\s)(&/]+/g;

        payload.forEach( el => {
            let temp = [];
            el.markets.forEach( mkt => {

                mkt.name = mkt.name.trim()
                
                if (temp.indexOf(mkt.marketId) === -1) {
                    temp.push(mkt.marketId)
                }

                let str = mkt.name.trim().toLowerCase()                
                str = str.replace(regex,"-");

                if(mkt.marketId == 259) {
                    mkt.key = str + '-' +mkt.handicap;
                    mkt.name = str + ' ' +mkt.handicap;
                } else {
                    mkt.key = str;
                }

                mkt.odds.forEach( odd =>{
                    let odn = odd.name.trim().toLowerCase()
                    odn = odn.replace(regex,"-")

                    odd.key = odn 
                })
            });
            el.market_ids = temp;

            if (betslip) 
            {
                let selectedMatch = betslip.find(x =>  x.id == el.id);
               
                if (selectedMatch && selectedMatch.id == el.id) 
                {
                    el.selected_odd_id = selectedMatch.market.oddId
                }
                else
                {
                    el.selected_odd_id = ""
                }
            }
            else
            {
                el.selected_odd_id = ""
            }
        })
       
        state.games = payload
    },
    [types.SET_PAGINATED_GAMES] (state,payload) { 

        let betslip = JSON.parse(localStorage.getItem("betslip"));
        let regex = /[\s)(&/]+/g;

        payload.forEach( el => {
            let temp = [];
            el.markets.forEach( mkt => {

                mkt.name = mkt.name.trim()
                
                if (temp.indexOf(mkt.marketId) === -1) {
                    temp.push(mkt.marketId)
                }

                let str = mkt.name.trim().toLowerCase()                
                str = str.replace(regex,"-");

                mkt.key = str

                mkt.odds.forEach( odd =>{
                    let odn = odd.name.trim().toLowerCase()
                    odn = odn.replace(regex,"-")

                    odd.key = odn 
                })
            });
            el.market_ids = temp;

            if (betslip) 
            {
                let selectedMatch = betslip.find(x =>  x.id == el.id);
               
                if (selectedMatch && selectedMatch.id == el.id) 
                {
                    el.selected_odd_id = selectedMatch.market.oddId
                }
                else
                {
                    el.selected_odd_id = ""
                }
            }
            else
            {
                el.selected_odd_id = ""
            }
        })
        payload.map( x => state.games.push(x));
        // state.games = payload
    },

    [types.GET_MEGA_JACKPOT_GAMES] (state,payload) { 
        let betslip = JSON.parse(localStorage.getItem("mega_jackpot"));
        let regex = /[\s)(&/]+/g;
        
        if(payload === 'Sorry no active jackpot') {
            state.jackpot_games = []
        } else {
            payload.forEach(el => {
            
                let temp = [];
                el.markets.forEach( mkt => {
                    if (temp.indexOf(mkt.marketId) === -1) {
                        temp.push(mkt.marketId)
                    }

                    let str = mkt.name.trim().toLowerCase()                
                    str = str.replace(regex,"-");
                    mkt.key = str;

                    mkt.odds.forEach( odd => {
                        let odn = odd.name.trim().toLowerCase();
                        odn = odn.replace(regex,"-");
                        odd.key = odn 
                    });

                });
                el.market_ids = temp;
                
                
    
                if (betslip) 
                {
                    let selectedMatch = betslip.find(x =>  x.id == el.id);
                
                    if (selectedMatch && selectedMatch.id == el.id) 
                    {
                        el.selected_odd_outcome = selectedMatch.selected_odd_outcome
                        el.selected_market = selectedMatch.selected_market
                        el.selected_mkt_id = selectedMatch.selected_mkt_id
                        el.selected_odd_id = selectedMatch.selected_odd_id
                        el.selected_marketId = selectedMatch.selected_marketId
                        el.selected_handicap = selectedMatch.selected_handicap
                    }
                    else
                    {
                        el.selected_odd_outcome = ""
                        el.selected_market = ""
                        el.selected_mkt_id = ""
                        el.selected_odd_id = ""
                        el.selected_marketId = ""
                        el.selected_handicap = ""
                    }
                }
                else
                {
                    el.selected_odd_outcome = ""
                    el.selected_market = ""
                    el.selected_mkt_id = ""
                    el.selected_odd_id = ""
                    el.selected_marketId = ""
                    el.selected_handicap = ""
                }
                    
            });

            payload.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.scheduled) - new Date(b.scheduled);
            });

            state.jackpot_games = payload
        }
    },

    [types.GET_MINI_JACKPOT_GAMES] (state,payload) { 
        let betslip = JSON.parse(localStorage.getItem("daily_jackpot"));
        let regex = /[\s)(&/]+/g;
        
        if(payload === 'Sorry no active jackpot') {
            state.daily_jackpot_games = []
        } else {
            payload.forEach(el => {
            
                let temp = [];
                el.markets.forEach( mkt => {
                    if (temp.indexOf(mkt.marketId) === -1) {
                        temp.push(mkt.marketId)
                    }

                    let str = mkt.name.trim().toLowerCase()                
                    str = str.replace(regex,"-");
                    mkt.key = str;

                    mkt.odds.forEach( odd => {
                        let odn = odd.name.trim().toLowerCase();
                        odn = odn.replace(regex,"-");
                        odd.key = odn 
                    });

                });
                el.market_ids = temp;
                el.started = 0;
                el.scores = null;
                
                
    
                if (betslip) 
                {
                    let selectedMatch = betslip.find(x =>  x.id == el.id);
                
                    if (selectedMatch && selectedMatch.id == el.id) 
                    {
                        el.selected_odd_outcome = selectedMatch.selected_odd_outcome
                        el.selected_market = selectedMatch.selected_market
                        el.selected_mkt_id = selectedMatch.selected_mkt_id
                        el.selected_odd_id = selectedMatch.selected_odd_id
                        el.selected_marketId = selectedMatch.selected_marketId
                        el.selected_handicap = selectedMatch.selected_handicap
                    }
                    else
                    {
                        el.selected_odd_outcome = ""
                        el.selected_market = ""
                        el.selected_mkt_id = ""
                        el.selected_odd_id = ""
                        el.selected_marketId = ""
                        el.selected_handicap = ""
                    }
                }
                else
                {
                    el.selected_odd_outcome = ""
                    el.selected_market = ""
                    el.selected_mkt_id = ""
                    el.selected_odd_id = ""
                    el.selected_marketId = ""
                    el.selected_handicap = ""
                }
                    
            });
            state.daily_jackpot_games = payload
        }
    },  

    [types.GET_MARKETS] (state,payload) { state.markets = payload },

    [types.SET_MARKETS] (state,payload) { state.all_markets = payload },

    [types.GET_TOP5_MENU] (state,payload) {
        payload.forEach(el => { 
            el.en_name = el.name.trim().split(" ").join("-").toLowerCase();                                     
        });
        state.top5 = payload 
    },

    [types.GET_SIDEBAR_MENU] (state,payload) { 

        function compare( a, b ) {
            if ( a.name < b.name ){
            return -1;
            }
            if ( a.name > b.name ){
            return 1;
            }
            return 0;
        }
        
        payload.sort(compare)

        function move(arr, old_index, new_index) {
            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }
            if (new_index >= arr.length) {
                var k = new_index - arr.length;
                while ((k--) + 1) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
           return arr;
        }

        const sindex = payload.findIndex( x => x.id === 10)

        

        payload.forEach(el => {  
            el.en_name = el.name.trim().split(" ").join("-").toLowerCase();          
            el.country.sort(compare);
        });

        state.menu = move (payload,sindex,0)
    },

    [types.UPDATE_NORMAL_BETSLIP] (state,payload) {
        if (payload != null && payload.length > 0) { 

            state.games.forEach( el => {
                let bet = payload.find( x => x.id == el.id)
                if(bet) {
                    el.selected_odd_id = bet.market.oddId
                }  else {
                    el.selected_odd_id = ""
                }
            })
        } else {
            state.games.forEach( el => {
                el.selected_odd_id = ""
            })
        }
        return state.games
    }, 
    
    [types.UPDATE_LIVE_BETSLIP] (state,payload) {
        
        if (payload != null && payload.length > 0) { 
            
            state.live.forEach( el => {
                let bet = payload.find( x => x.id == el.id)
                if(bet) {
                    el.selected_odd_id = bet.market.oddId
                }  else {
                    el.selected_odd_id = ""
                }
            })
        } else {
            state.live.forEach( el => {
                el.selected_odd_id = ""
            })
        }
        return state.live
    }, 

    [types.UPDATE_JACKPOT_BETSLIP] (state,payload) {
        let type = payload.type
        let slip = payload.slip

        if (type == 'mega_jackpot') {
            if (slip != null && slip.length > 0) {  
                state.jackpot_games.forEach( el => {
                    let bet = slip.find( x => x.id == el.id)
                    
                    if(bet) {
                        el.selected_odd_outcome = bet.selected_odd_outcome
                        el.selected_market = bet.selected_market
                        el.selected_mkt_id = bet.selected_mkt_id
                        el.selected_odd_id = bet.selected_odd_id
                        el.selected_marketId = bet.selected_marketId
                        el.selected_handicap = bet.selected_handicap
                    }  else {
                        el.selected_odd_outcome = ""
                        el.selected_market = ""
                        el.selected_mkt_id = ""
                        el.selected_odd_id = ""
                        el.selected_marketId = ""
                        el.selected_handicap = ""
                    }
                })
            } else {
                state.jackpot_games.forEach( el => {
                    el.selected_odd_outcome = ""
                    el.selected_market = ""
                    el.selected_mkt_id = ""
                    el.selected_odd_id = ""
                    el.selected_marketId = ""
                    el.selected_handicap = ""
                })
            }
            
            return state.jackpot_games
        } else {
            if (slip != null && slip.length > 0) {  
                state.daily_jackpot_games.forEach( el => {
                    let bet = slip.find( x => x.id == el.id)
                    
                    if(bet) {
                        el.selected_odd_outcome = bet.selected_odd_outcome
                        el.selected_market = bet.selected_market
                        el.selected_mkt_id = bet.selected_mkt_id
                        el.selected_odd_id = bet.selected_odd_id
                        el.selected_marketId = bet.selected_marketId
                        el.selected_handicap = bet.selected_handicap
                    }  else {
                        el.selected_odd_outcome = ""
                        el.selected_market = ""
                        el.selected_mkt_id = ""
                        el.selected_odd_id = ""
                        el.selected_marketId = ""
                        el.selected_handicap = ""
                    }
                })
            } else {
                state.daily_jackpot_games.forEach( el => {
                    el.selected_odd_outcome = ""
                    el.selected_market = ""
                    el.selected_mkt_id = ""
                    el.selected_odd_id = ""
                    el.selected_marketId = ""
                    el.selected_handicap = ""
                })
            }
            
            return state.daily_jackpot_games
        }
    },

    [types.SET_SINGLE_MATCH]  (state,payload) {
       
        let localbetslip = JSON.parse(localStorage.getItem("betslip"))

        let regex = /[\s)(&/]+/g;
        payload.markets.forEach( mkt => {
            let str = mkt.name.trim().toLowerCase()
            let kstr = str.replace(regex,"-");
            
            if(mkt.marketId == 259) {
                mkt.key = kstr + '-' + mkt.handicap;
                mkt.name = str + ' ' + mkt.handicap;
            } else {
                let h = (mkt.handicap != 'n/a') ? ' ' + mkt.handicap : ''
                mkt.key = kstr + h;
            }

            mkt.odds.forEach( odd =>{
                let odn = odd.name.trim().toLowerCase()
                odn = odn.replace(regex,"-");                
                odd.key = odn 
            })
        })
        

        if(payload.markets)
        {       
            if (localStorage.getItem("betslip") !== null) {
                let selectedMatch = localbetslip.find(x => x.id == payload.id);
                if (selectedMatch) {
                    payload.selected_odd_id = selectedMatch.market.oddId
                } else {
                    payload.selected_odd_id = ""
                }   
            } else {
                payload.selected_odd_id = ""
            } 

            state.single_match = payload
            
            return state.single_match
        }

    },

    [types.UPDATE_SINGLE_MATCH] (state,payload) {
        if (payload != null && payload.length > 0) {  
           
            let bet = payload.find( x => x.id ==  state.single_match.id);
                        
            if(bet) {
                state.single_match.selected_odd_id = bet.market.oddId
            }  else {
                state.single_match.selected_odd_id = ""
            }
            
        } else {
            state.single_match.selected_odd_id = ""
        }
               
        return state.single_match
    },

    [types.UPDATE_LIVE_MATCH] (state,payload) {
        if (payload != null && payload.length > 0) {  
           
            let bet = payload.find( x => x.id ==  state.single_live.id);
               
            if(bet) {
                state.single_live.selected_odd_id = bet.market.oddId
            }  else {
                state.single_live.selected_odd_id = ""
            }
            
        } else {
            state.single_live.selected_odd_id = ""
        }
        
        return state.single_live
    },

    [types.SET_META] (state,payload) { state.meta = payload },

    [types.SET_LIVE] (state,payload) {
        if(payload) {
            const { game,sport} = payload
            
            let ids = getSport(sport)
        
            let betslip = JSON.parse(localStorage.getItem("betslip"));
            let regex = /[\s)(&/]+/g;
            let temp = [];
            let gid = game.id
        
            game.markets.forEach( mkt => {
                mkt.name = mkt.name.trim()
                
                if (temp.indexOf(mkt.id) === -1) {
                    temp.push(mkt.id)
                }

                let str = mkt.name.trim().toLowerCase()                
                str = str.replace(regex,"-");

                if(mkt.id != 18) {
                    mkt.key = str;
                } 
                
                mkt.odds.forEach( odd => {
                    let odn = odd.name.trim().toLowerCase()
                    odn = odn.replace(regex,"-")
                    odd.key_ = odn 
                    let ff = state.hash.find( x => x.oid == odd.key);

                    if(mkt.id == 18) {
                        mkt.key = odd.specifier;
                    }

                    if(state.hash.length == 0) {
                        if(ids.includes(odd.marketId))
                        {
                            state.hash.push({
                                id: game.id,
                                oid: odd.key,
                                odd: odd.odds,
                                up: false,
                                down: false,
                            })
                        } 
                    } 
                    else {
                        if(ids.includes(odd.marketId))
                        {
                            
                            if(ff !== undefined) {
                                if(ff.odd > odd.odds ) {
                                    ff = { ...ff,down:true,odd:odd.odds}
                                } else if(ff.odd < odd.odds) {
                                    ff = { ...ff,up:true,odd:odd.odds}
                                } else {
                                    ff = { ...ff,up:false}
                                    ff = { ...ff,down:false}
                                }
                                
                                ff = { ...ff}
                            } else {
                                if(ids.includes(odd.marketId))
                                {
                                    state.hash.push({
                                        id: game.id,
                                        oid: odd.key,
                                        odd: odd.odds,
                                        up: false,
                                        down: false
                                    })
                                }   
                            }
                        }
                    }
                
                    if(betslip) {
                        let betcheck = betslip.find(x => x.id == game.id);
                        if(betcheck){
                            let index = betslip.indexOf(betcheck);
                            
                            if( betslip[index].market.oddId == odd.key) {
                                betslip[index].market.changed = (betslip[index].market.odd != odd.odds) ? true : false;
                                betslip[index].market.odd = odd.odds;
                                betslip[index].market.status = odd.status;
                            } 
                        }
                    
                        localStorage.setItem('betslip', JSON.stringify(betslip));
                    }   
                    if(ff !== undefined) {
                        odd.up = ff.up
                        odd.down = ff.down
                    }
                    odd = {...odd}
                });
                
            });

            game.market_ids = temp;

            if (betslip) 
            {
                let selectedMatch = betslip.find(x =>  x.id == game.id);
                
                if (selectedMatch && selectedMatch.id == game.id) 
                {
                    game.selected_odd_id = selectedMatch.market.oddId
                }
                else
                {
                    game.selected_odd_id = ""
                }
            }
            else
            {
                game.selected_odd_id = ""
            }

            BetslipService.setSubjectBetslip();

            if(!state.live_ids.includes(gid)) {
                if(game.sportId == sport) {
                    state.live_ids.push(gid);
                    state.live.push(game)
                }
                
            } else {
                let index = state.live.findIndex( x => x.id == gid);
                if(index !== -1) {
                    Object.assign(state.live[index],game);
                } 
            }
        }
    },

    [types.SET_SINGLE_LIVE] (state,game) {
        const { game:payload} = game;

        let betslip = JSON.parse(localStorage.getItem("betslip"));
        let regex = /[\s)(&/]+/g;
        let temp = [];
        payload.markets.forEach( mkt => {
            mkt.name = mkt.name.trim()
            
            if (temp.indexOf(mkt.id) === -1) {
                temp.push(mkt.id)
            }

            let str = mkt.name.trim().toLowerCase()                
            str = str.replace(regex,"-");
            mkt.key = str;
            
            mkt.odds.forEach( (odd) => {
                let odn = odd.name.trim().toLowerCase()
                odn = odn.replace(regex,"-")
                odd.key_ = odn 
                let ff = state.mhash.find( x => x.oid == odd.key);

                if(state.mhash.length == 0) {
                    state.mhash.push({
                        id: payload.id,
                        oid: odd.key,
                        odd: odd.odds,
                        up: false,
                        down: false
                    })
                } 
                else {
                    if(ff !== undefined) {
                        if(ff.odd > odd.odds ) {
                            ff = { ...ff,down:true,odd:odd.odds}
                        } else if(ff.odd < odd.odds) {
                            ff = { ...ff,up:true,odd:odd.odds}
                        } else {
                            ff = { ...ff,up:false}
                            ff = { ...ff,down:false}
                        }
                        
                        ff = { ...ff}
                    } else {
                        state.mhash.push({
                            id: payload.id,
                            oid: odd.key,
                            odd: odd.odds,
                            up: false,
                            down: false
                        }) 
                    }
                }
               
                if(betslip) {
                    let betcheck = betslip.find(x => x.id == payload.id);
                    if(betcheck){
                        let index = betslip.indexOf(betcheck);
                        
                        if( betslip[index].market.oddId == odd.key) {
                            betslip[index].market.changed = (betslip[index].market.odd != odd.odds) ? true : false;
                            betslip[index].market.odd = (odd.status != 1) ? 1 : odd.odds;
                            betslip[index].market.status = odd.status;
                        } 
                    }
                   
                    localStorage.setItem('betslip', JSON.stringify(betslip));
                }   
                if(ff !== undefined) {
                    odd.up = ff.up
                    odd.down = ff.down
                }
                odd = {...odd}
            });
        });
        
        payload.market_ids = temp;
        
        if (betslip) 
        {
            let selectedMatch = betslip.find(x =>  x.id == payload.id);
            
            if (selectedMatch && selectedMatch.id == payload.id) 
            {
                payload.selected_odd_id = selectedMatch.market.oddId
            }
            else
            {
                payload.selected_odd_id = ""
            }
        }
        else
        {
            payload.selected_odd_id = ""
        }

        BetslipService.setSubjectBetslip();

        if(state.single_live) {
            Object.assign(state.single_live,payload);
            // state.single_live = {...state.single_live,payload};
        } else {
            state.single_live = payload;
        }
        
    },
    
    [types.RESET_LIVE] (state) {
        state.live.splice(0);
        state.live_ids.splice(0);
        state.hash.splice(0)
        state.mhash.splice(0)
    },

    [types.LIVE_COUNT] (state,payload) {
        state.liveCount = payload;
    },
    
    [types.LIVE_MENU] (state,payload) {
        if(Array.isArray(payload) && payload.length > 0) {
            payload.map( x => {
                let route = x.name.trim();
                let str = route.replace(/\s/g, '')
                x.str = str
            })
        }
        state.liveMenu = payload;
    },

    [types.LIVE_SPORT] (state, payload) {
        let betslip = JSON.parse(localStorage.getItem("betslip"));
        let regex = /[\s)(&/]+/g;
        let temp = [];

        if(Array.isArray(payload)) {
            payload.map( game => {

                if(!state.live_ids.includes(game.id)) {
                    state.live_ids.push(game.id);
                }
                game.markets.forEach( mkt => {
                    mkt.name = mkt.name.trim()
                    
                    if (temp.indexOf(mkt.id) === -1) {
                        temp.push(mkt.id)
                    }
        
                    let str = mkt.name.trim().toLowerCase()                
                    str = str.replace(regex,"-");
        
                    if(mkt.id == 18) {
                       
                        mkt.name = str;
                    } else {
                        mkt.key = str;
                    }
                    
                    mkt.odds.forEach( odd => {
                        let odn = odd.name.trim().toLowerCase()
                        odn = odn.replace(regex,"-")
                        odd.key_ = odn 
                        let ff = state.hash.find( x => x.oid == odd.key);
        
                        state.hash.push({
                            id: game.id,
                            oid: odd.key,
                            odd: odd.odds,
                            up: false,
                            down: false
                        })
                       
                        if(betslip) {
                            let betcheck = betslip.find(x => x.id == game.id);
                            if(betcheck){
                                let index = betslip.indexOf(betcheck);
                                
                                if( betslip[index].market.oddId == odd.key) {
                                    betslip[index].market.changed = (betslip[index].market.odd != odd.odds) ? true : false;
                                    betslip[index].market.odd = odd.odds;
                                    betslip[index].market.status = odd.status;
                                } 
                            }
                           
                            localStorage.setItem('betslip', JSON.stringify(betslip));
                        }   
                        if(ff !== undefined) {
                            odd.up = ff.up
                            odd.down = ff.down
                        }
                        odd = {...odd}
                    });
                    
                });
        
                game.market_ids = temp;
        
                if (betslip) 
                {
                    let selectedMatch = betslip.find(x =>  x.id == game.id);
                    
                    if (selectedMatch && selectedMatch.id == game.id) 
                    {
                        game.selected_odd_id = selectedMatch.market.oddId
                    }
                    else
                    {
                        game.selected_odd_id = ""
                    }
                }
                else
                {
                    game.selected_odd_id = ""
                }

                return game
            })

            BetslipService.setSubjectBetslip();
            state.live = payload;
        }
    },  
    
    [types.UPDATE_LIVE_SLIPS] (_,payload) {
        if(payload) {
            let betslip = JSON.parse(localStorage.getItem("betslip"));
            if(betslip) {
                payload.forEach( el => {
                    const betcheck = betslip.find( x => x.market.oddId == el.id);
                    
                    if(betcheck) {
                        let index = betslip.indexOf(betcheck);

                        if( betslip[index].market.oddId == el.id) {
                            betslip[index].market.status = el.status;
                            betslip[index].market.changed = (betslip[index].market.odd != el.odds) ? true : false;
                            betslip[index].market.odd = el.odds;
                        }
                    }
                });
                localStorage.setItem('betslip', JSON.stringify(betslip));        
                BetslipService.setSubjectBetslip();
            }
        }
       

    },

    [types.LIVE_BETSTOP] (state,payload) {
        let index = state.live.findIndex( x => x.id == payload);
        let arg = state.live.find( x => x.id == payload);

        if(arg) {
            arg.markets.forEach( mkt => {
                mkt.odds.forEach( odd => {
                    odd = { ...odd, odds:0} 
                    return odd
                })
            })
        }
        if(index !== -1) {
            Object.assign(state.live[index],arg);
        } 
    },
    
    [types.LIVE_ENDED] (state,payload) {
        let index = state.live.findIndex( x => x.id == payload);
        if(index !== -1) {
            state.live.splice(index,0)
        } 
    }

}
