<template>
  <div v-frag>
    <template v-for="mar in market.odds">
      <span 
        v-if="select.id == mar.outcomeId"
        :key="mar.id"
        class="odd"
        @click="addToBetslip(mar,game,market)"
        :class ="{ 'selected' : mar.id == game.selected_odd_id || sharedBet.find( x => x.odd_id == mar.id ) }"
      >{{ parseFloat(mar.odds).toFixed(2)  }}</span>
    </template>
  </div>
  
</template>
<script>
import BetslipService from "@/services/betslip";
import {mapGetters} from "vuex";
export default {
  props:['select','game','market'],
  methods:{
    addToBetslip: function(odds, match, market) {  

      let obj= {
        id:match.id,
        home: match.home,
        away:match.away,
        sportId: match.sportId,
        scheduled: match.scheduled,

        market: {
            id:market.marketId,
            mid:market.id,
            mhandicap: market.handicap,
            mname: market.name,
            oddId: odds.id,
            outcome: odds.outcomeId,
            pick: odds.name,
            status: odds.status,
            odd: odds.odds,
            key: market.key,
            oddKey:odds.key,
            type: 'prematch'
        }
      } 
      let check = this.sharedBet.find(x => x.match_id == match.id);
      if(check)
      {
        this.$store.dispatch('removeSharedBets',match.id);
      }

      // add bet to slip
      BetslipService.addBetslip(obj);
    }
  },
  computed: {
    ...mapGetters(['sharedBet']),
  }
}
</script>
<style scoped>
  .odd {
    background-color: var(--bg-blue);
    padding: 8px 12px;
    border-radius: 5px;
    color: var(--a-text);
    text-align: center;
  }
  .odd.selected {
    background-color: var(--bg-orange);
  }
</style>