export const getters = {
    type: state =>  state.casino,
    casino_type: state => state.casino_type,
    live_type: state => state.live_type,
    casinos:state=>state.casinoGames,
    loadingGames:state => state.loadingGames,
    loadingVirtuals:state =>state.loadingVirtuals,
    loadingSlots:state => state.loadingSlots,
    liveCasinoGames:state => state.liveCasinoGames,
    virtualGames:state => state.virtualGames,
    casinoBets:state => state.bets,
    lobby:state => state.lobby

}