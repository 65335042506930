<template>
    <div v-frag>
        <div class="main-carousel" v-if="!slides">
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="item active" v-if="locale == 'en' && !slides" >
                        <img :src="`${publicPath}images/Rahisibet-en.jpg`" alt="en-rahisibet">
                    </div>
                    <div class="item active" v-if="locale == 'fr'  && !slides">
                        <img :src="`${publicPath}images/Rahisibet-fr.jpg`" alt="en-rahisibet">
                    </div>          
                </div>
            </div>
        </div>
        <Carousel v-bind:autoplay="true" :loop="true" :per-page=1 :pagination-enabled="false" :autoplay-timeout=5000 v-else>
            <Slide v-for="(slide,index) in slide.filter( x => x.language == locale)" :key="`cd${index}`">
                <img :src="getImgUrl(slide)"  :alt="index"  class="img-c"/>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import moment from 'moment';
import { mapGetters } from "vuex";
export default {
    name: 'carousel',
    props:['slides'],
    components: {
        Carousel,
        Slide
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            locale: this.$i18n.locale
        }
    },
    computed: {
        ...mapGetters({
            geo:'geo',
            user_:'user',
        }),
        chk() {
            if(this.user_ && this.user_.iso) {
                return this.user_.iso;
            }
            else if(this.geo && this.geo.country_code2) {
                return this.geo.country_code2;
            } else {
                return "KE";
            }
        },
        slide() {
            var slid = (this.chk != 'BI') ? this.slides.filter( x=> x.id != 1 && x.id != 21) : this.slides;
            return slid;
        }
    },
    methods: {
        getImgUrl:function (slide) {
            var url = "https://crm.rahisibet.com/storage/slides_uploads/" + slide.name
            return url;
        },
        redirect:function (params) {
            this.$router.push("/single-match/" + params);
        },
        format_date:function (value){
            if (value) {
                return moment(String(value)).format('HH:mm - D/MM/YYYY')
            }
        },
        toCasino: function () {
            this.$router.push("/casino-home");
        }
    }, 
    mounted(){
        this.$store.dispatch("fetch_slides")
    }
}
</script>

<style scoped>
    .carousel-inner {
        /* background-image: url('background.jpg'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
       
    }
    .d-flex {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .teams { 
        font-weight: 600;
        text-transform: uppercase;
    }
    .home {
        text-align: right;
    }
    .flex-grow {
        flex-grow: 1;
        flex-shrink: 2;
    }
    .away {
        text-align: left;
    }
    img {
        cursor: pointer;
        width: 100% !important;
        max-height: 200px;
    }

    @media screen and (max-width: 767px) {
        .d-flex {
            padding: 20px 10px;
        }
        .schedule {
            padding: 0px 10px;
            font-weight: 500;
            font-size: 14px;
        }
        .teams {
            font-size: 14px;
        }
    }

    @media screen and (min-width: 768px) { 
        .d-flex {
            padding: 70px 50px;
        }  
        .teams {
            font-size: 28px;
        }   
        .schedule { 
            padding: 10px 60px;
            font-size:20px;
            font-weight: 500;
        }  
    }
</style>