// let BASE_URL = process.env.VUE_APP_BASE_URL
let BASE_URL = process.env.VUE_APP_STAGING_BASE_URL

// STAGING ENDPOINTS
// let FINANCE = process.env.VUE_APP_STAGING_FINANCE
// let NEW_API = process.env.VUE_APP_STAGING_NEW_API
// let ASTROPAY_DEPOSIT =process.env.VUE_APP_STAGING_ASTROPAY_DEPOSIT
// let ASTROPAY_WITHDRAWAL = process.env.VUE_APP_STAGING_ASTROPAY_WITHDRAWAL
// let VIRTUAL_DEPOSIT = process.env.VUE_APP_STAGING_VIRTUAL_DEPOSIT
// let VIRTUALPAY = process.env.VUE_APP_STAGING_VIRTUALPAY
// let RATES = process.env.VUE_APP_STAGING_RATES
// export const JIBUPAY_DEPOSIT = process.env.VUE_APP_STAGING_JIBUPAY_DEPOSIT

// PRODUCTION ENDPOINTS
let FINANCE = process.env.VUE_APP_LIVE_FINANCE
let NEW_API = process.env.VUE_APP_LIVE_NEW_API
let ASTROPAY_DEPOSIT =process.env.VUE_APP_LIVE_ASTROPAY_DEPOSIT
let ASTROPAY_WITHDRAWAL = process.env.VUE_APP_LIVE_ASTROPAY_WITHDRAWAL
let VIRTUAL_DEPOSIT = process.env.VUE_APP_LIVE_VIRTUAL_DEPOSIT
let VIRTUALPAY = process.env.VUE_APP_LIVE_VIRTUALPAY
let RATES = process.env.VUE_APP_LIVE_RATES
export const JIBUPAY_DEPOSIT = process.env.VUE_APP_LIVE_JIBUPAY_DEPOSIT

// let FINANCE = "https://test.rahisibet.com/ApiSdk/api/v2/"
// let NEW_API = "https://test.rahisibet.com/ApiSdk/api/v2/"
// let ASTROPAY_DEPOSIT = "https://test.rahisibet.com/ApiSdk/api/v2/app/b2c";
// let ASTROPAY_WITHDRAWAL = "https://test.rahisibet.com/ApiSdk/api/v2/app/c2b";
// let VIRTUAL_DEPOSIT = "https://api.rahisibet.com/api/v2/app/token/digestor";
// let VIRTUALPAY = 'http://127.0.0.1:8000/payment'
// let RATES = "https://api.rahisibet.com/api/app/test"
// export const JIBUPAY_DEPOSIT = "https://test.rahisibet.com/ApiSdk/api/v2/app/c2b"

export const JIBUPAY_WITHDRAWAL = FINANCE + "app/b2c"

export const LOGIN_URL = NEW_API + 'app/login'
export const LOGOUT_URL = NEW_API + 'app/logout'
export const SLIDES_URL = NEW_API + "app/slides"
export const REBET_URL = BASE_URL + "app/rebet"
export const INVITE_URL = NEW_API + "app/invite/friend"

export const UPCOMING_SPORTS_URL = NEW_API + "games/upcoming"
export const TODAY_GAMES_URL = NEW_API + "today/games"
export const M_FEATURED_GAMES_URL = NEW_API + "featured"
export const HIGHLIGHTS_URL = BASE_URL + "highlights" 
export const RESULTS_URL = NEW_API + "app/results" 
export const TOURNAMENT_URL = NEW_API + "tournament"
export const PRINTABLE_GAMES = NEW_API + "printable/matches"
export const LEADERBOARD = BASE_URL + "app/leader/board"

export const TOP_LEAGUES_URL = NEW_API + "top_leagues"
export const SIDEBAR_MENU_URL = NEW_API + "menu"
export const SEARCH_GAME_BY_DATE = NEW_API + "app/filter/fixtures"

export const JACKPOT_SLIDES_URL = BASE_URL + "app/jackpot/slides" 
export const MEGA_JACKPOT_GAMES_URL = BASE_URL + "app/jackpot/fixtures"
export const MINI_JACKPOT_GAMES_URL = BASE_URL + "autobet/matches"
export const SINGLE_MATCH_URL = NEW_API + "match"

export const BETS_URL = NEW_API + "app/bets"
export const BETS_BY_DATE_URL = NEW_API + "app/mobile/bets/filter"
export const BETS_SHARE_URL = NEW_API + "app/bets/share"
export const BETS_BY_ID_URL = NEW_API + "app/mobile/bets"

export const REGISTER_URL = NEW_API + "app/register"
export const VERIFICATION_URL = NEW_API + "app/verify/account"
export const FORGOT_PASSWORD_URL = NEW_API + "app/reset/otp"
export const RESET_PASSWORD_URL = NEW_API + "app/reset/password"

export const PLACE_NORMAL_BET_URL = NEW_API + "place/bets" 
export const PLACE_STAGING_BET_URL =  NEW_API + "place/bets" 
export const SHARE_NORMAL_BET_URL = NEW_API + "app/web/share_bet"
export const SHARE_SLIP_BET_URL = NEW_API + "app/shared/bet"
export const MINIJACKPOT_BET_URL = BASE_URL + "place/auto_bet"
export const MEGAJACKPOT_BET_URL = BASE_URL + "place/bet/jackpot"

export const PROFILE_URL = NEW_API + "app/profile"
export const UPDATE_PASSWORD = NEW_API + "app/password"
export const MESSAGES_URL = NEW_API + "app/messages/"
export const MESSAGE_URL = NEW_API + "app/message/"
export const USER_DETAILS_URL = NEW_API + "app/user/details"
export const CASINO_BETS = "https://casino.rahisibet.com/api/v2/bets"
export const CASINO = "https://api.prerelease-env.biz/IntegrationService/v3/http/CasinoGameAPI/game/url/?"
export const TOKEN = "https://api.rahisibet.com/api/authenticate"

//casino endpoints
export const CASINO_GAMES_URL = "https://casino.rahisibet.com/api/v2/casino/getGames"
export const CASINO_GAME_URL = "https://casino.rahisibet.com/api/casino/url?"
export const ASTROPAY_URL = ASTROPAY_DEPOSIT
export const ASTROPAY_wURL = ASTROPAY_WITHDRAWAL
export const VIRTUALPAY_URL = VIRTUAL_DEPOSIT
export const VIRTUALPAY_POP = VIRTUALPAY
export const RATES_URL = RATES

export const secureLogin = "rhsbts_wwwrahisibetcom";
export const stylename = "rhsbts_wwwrahisibetcom";
export const lobbyUrl = 'https%3A%2F%2Frahisibet.com';
export const secretKey = 'testKey';
export const platform = 'MOBILE';
export const prodSecretKey = '22c8p7GkPeB4M5cN';
export const prodSecureLogin = 'rhsbts_rahisibet';
export const prodStyleName = 'rhsbts_rahisibet';

//Live games
export const LIVE_COUNT = NEW_API + "live/games"
export const LIVE_MENU =  NEW_API + "live/menu"
export const LIVE_SPORT=  NEW_API + "live/sport"
export const LIVE_MATCH=  NEW_API + "live/match"
export const LIVE_SLIP=  NEW_API + "live/slip"