import {markets} from "@/models/live.js"
export const state ={
    slides:[],
    games:[],
    markets:{},
    all_markets:{},
    menu:[],
    top5:[],
    jackpot_slides:[],
    jackpot_games:[],
    daily_jackpot_games:[],
    single_match:[],
    meta:null,
    live:[],
    live_ids:[],
    live_markets: markets,
    hash: [],
    mhash: [],
    betslip:null,
    liveCount:null,
    liveMenu:[],
    sportId:1,
    single_live:null,
    room:'Soccer',
}