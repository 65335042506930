<template>
  <div class="desktop-sidebar">
    <ul class="list-unstyled" v-if="currentRouteName != 'live' && currentRouteName != 'single-live-match'">
      <li v-for="(sport,i) in menu" :key="'sp-'+i" >
        <a @click.prevent="getUpcomingGames(sport.id)" :class="{'text-orange' : markets.sportId == sport.id}">
          <i class="icofont-football-alt" v-if="sport.id == 10"></i>
          <i class="icofont-kick" v-if="sport.id == 491393"></i>
          <!-- Soccer -->
          <i class="icofont-basketball" v-if="sport.id == 4"></i>
          <i class="icofont-dart" v-if="sport.id == 8"></i>
          <!-- basketball -->
          <i class="icofont-baseball" v-if="sport.id == 3"></i>
          <!-- baseball -->
          <i class="icofont-hockey" v-if="sport.id == 15"></i>
          <!-- ice hockey -->
          <i class="icofont-tennis" v-if="sport.id == 24 "></i>
          <i class="icofont-jumping" v-if="sport.id == 2 "></i>
          <i class="icofont-table-tennis" v-if="sport.id == 269467" ></i>
          <!-- tennis -->
          <i class="demo-icon icon-handball" v-if="sport.id == 99614">&#xe825;</i>
          <!-- handball -->
          <!-- <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe832;</i> -->
          <!-- boxing -->
          <i class="icofont-motor-biker" v-if="sport.id == 16"></i>
          <!-- motor-bike -->
          <i class="icofont-rugby" v-if="sport.id == 73744 || sport.id == 73743"></i>
          <!-- rugby -->
          <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe802;</i>
          <!-- cricket -->
          <i class="icofont-volleyball" v-if="sport.id == 91189"></i>
          <!-- volleyball -->
          <i class="icofont-rugby-player" v-if="sport.id == 17"></i>
          <i class="icofont-billiard-ball" v-if="sport.id == 22"></i>
          {{ $t('sidebar.' + sport.en_name )  }}
        </a>
      </li>
    </ul>

    <ul class="list-unstyled" v-if="liveMenu && liveMenu.length > 0 && currentRouteName == 'live' || currentRouteName == 'single-live-match'">
      <li v-for="(sport,i) in liveMenu" :key="'spl-'+i" >
        <a @click.prevent="getLiveGames(sport)" :class="{'text-orange' : sport.str == $route.params.sport}">
          <i class="icofont-football-alt" v-if="sport.id == 1"></i>
          <i class="icofont-kick" v-if="sport.id == 491393"></i>
          <!-- Soccer -->
          <i class="icofont-basketball" v-if="sport.id == 2"></i>
          <i class="icofont-dart" v-if="sport.id == 8"></i>
          <!-- basketball -->
          <i class="icofont-baseball" v-if="sport.id == 3"></i>
          <!-- baseball -->
          <i class="icofont-hockey" v-if="sport.id == 4"></i>
          <!-- ice hockey -->
          <i class="icofont-tennis" v-if="sport.id == 5 "></i>
          <!-- <i class="icofont-jumping" v-if="sport.id == 2 "></i> -->
          <i class="icofont-table-tennis" v-if="sport.id == 20" ></i>
          <!-- tennis -->
          <i class="demo-icon icon-handball" v-if="sport.id == 6">&#xe825;</i>
          <!-- handball -->
          <!-- <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe832;</i> -->
          <!-- boxing -->
          <i class="icofont-motor-biker" v-if="sport.id == 16"></i>
          <!-- motor-bike -->
          <i class="icofont-rugby" v-if="sport.id == 73744 || sport.id == 73743"></i>
          <!-- rugby -->
          <i class="demo-icon icon-cricket" v-if="sport.id == 6666">&#xe802;</i>
          <!-- cricket -->
          <i class="icofont-volleyball" v-if="sport.id == 34"></i>
          <!-- volleyball -->
          <i class="icofont-rugby-player" v-if="sport.id == 12"></i>
          <i class="icofont-billiard-ball" v-if="sport.id == 22"></i>
            <template v-if="$i18n.locale == 'en'">{{ sport.name }}</template>
            <template v-if="$i18n.locale == 'fr'">{{ sport.frName }}</template>
            <span style="margin-left: 20px;">{{ sport.count }}</span>
        </a>
      </li>
    </ul>
  </div>
  
</template>
<script>
import {mapGetters} from "vuex" 
export default {
  data() {
    return {
      polling:null,
      spid:1,
      rnm: ''
    }
  },
  computed:{
    ...mapGetters({
      menu: 'menu',
      markets: 'markets',
      liveMenu: 'liveMenu',
      liveCount: 'liveCount',
      live_markets:'live_markets'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    market() {
      return this.live_markets.find( x=> x.sportId == this.spid);
    },
  },
  mounted(){
    this.$store.dispatch("fetch_sidebar_menu")

    if(this.currentRouteName == 'live') {
      this.$store.dispatch('get_live_menu');

      this.polling = setInterval( async () => {
        await this.$store.dispatch("get_live_menu")
      },10000);
    }
  },
  methods: {
    getUpcomingGames: function (id) {  
      let url = "/sport/" + id
      if (this.$route.path != url ) {
          this.$router.push(url)
      }
    },
    getLiveGames: function(sport) {
      let route = sport.name.trim();
      let str = route.replace(/\s/g, '')

      if(this.$route.params.sport != str) {
        this.$router.push(`/live/${str}`)
      }
    },

  },
  beforeDestroy () {
    clearInterval(this.polling);
  }
  
}
</script>
<style scoped>
 .desktop-sidebar {
    /* padding: 10px 0px; */
    width: 423px;
    position: -webkit-sticky;
    position: sticky !important;
    top: 100px;
    height: auto;
    align-self: flex-start
  }
   .desktop-sidebar a {
    color: var(--a-text);
    padding: 8px 5px ;
    display: block;
    text-decoration: none;
    font-weight: 500;
  }
  .desktop-sidebar li:first-child a {
    padding-top: 0px;
  }
  .desktop-sidebar a i {
    margin-right: 5px;
  }
</style>