<template>
  <!-- match div -->
  <div class=" feeds-matches" >
    <div class="feed-schedule">
      <p v-if="$i18n.locale == 'en'" class="league-schedule">{{ iso(game.country).name }} - {{ $t('feeds.game-id') }}: {{ game.id }} - {{ timezone(game.scheduled)  }}</p>
      <p v-if="$i18n.locale == 'fr'" class="league-schedule">{{ iso(game.country).fr_name }} - {{ $t('feeds.game-id') }}: {{ game.id }} - {{ timezone(game.scheduled)  }}</p>
      <router-link 
        :to="'/'+game.sportId + '/single-match/' + game.id" tag="p"  
        class="text-right more-markets" v-if="game.marketCount != 0"
        :class="{ 'text-orange':game.selected_odd_id == '','text-green':game.selected_odd_id != '' }"
      >
        +{{ game.marketCount }} {{ $t('feeds.markets')}}
      </router-link>
    </div>
    <div class="d-grid two-column">
      <div class="teams" @click="goToMoremarkets()">      
        <p>{{ game.home }}</p>
        <p>{{ game.away }}</p>
      </div>
      <div class="d-grid" :class="columnClass">
        <template v-if="Array.isArray(markets) || (markets && markets.odds.length != marketCount.selections.length)">
          <no_odd v-for="(_,i) in marketCount.selections.length" :key="`sss${i}`"/>
        </template>
        
        <template v-if="markets && markets.odds != undefined && markets.odds.length == marketCount.selections.length">
          <div v-frag v-for="(select,i) in marketCount.selections" :key="`mar${i}`">
            <odd  :select="select" :game="game" :market="markets"/>
          </div>
        </template>
                
      </div>
    </div>
  </div>
  <!-- end -->
</template>
<script>
import odd from './button.vue';
import no_odd from './no-odds-button.vue';
import { getCountryISO2 } from "@/utils/utility";
import { mapGetters } from "vuex";

export default {
  props:['game','id','market'],
  components:{
    odd,
    no_odd
  },
  computed:{
    ...mapGetters({ geo: 'geo'}),
    markets() {
      return this.game.markets.find( x => x.key == this.id  ) || [];
    },
    marketCount() {
      
      let count = this.market.markets.find( x => x.key == this.id ) || 0;

      if(count) {
        return count;
      } else {
        return [];
      }
    },
    columnClass() {
      let count = this.market.markets.find( x => x.key == this.id );

      if (count && count.selections.length > 2) {
        return "three-column";
      } else if(count && count.selections.length == 2) {
        return "two-column";
      } else {
        return "one"
      }
    },
   
  },
  methods: { 
    iso: function (code) {
      const res = getCountryISO2(code,'sidebar')
      if(res) {
          return res
      } else {
          return {
              "code": "FM",
              "code3": "DEF",
              "fr_name": code,
              "name": code,
              "number": "248"
          }
      }
    },
    goToMoremarkets: function() {
      this.$router.push('/'+ this.game.sportId + '/single-match/' + this.game.id)
    },
    timezone(date) {
      let offset = this.geo.time_zone.offset - 2;
      if(offset > 0) {
       return this.$moment(date).add(offset, 'hours').format('D/MM,HH:mm');
      } else if(offset < 0) {
        return this.$moment(date).subtract(Math.abs(offset), 'hours').format('D/MM,HH:mm');
      } else {
        return this.$moment(date).format('D/MM,HH:mm');
      }

    }
  },
  watch: {
    locale: function (newLocale) {
      this.locale = newLocale
    }
  }
}
</script>
<style scoped>
  .feeds-matches {
    cursor: pointer;
  }
  .d-grid {
    display: grid;
    gap: 5px !important;
    align-content: center;
    justify-content: center;
    grid-template-rows: 35px;
  }
  .main-two-column {
    grid-template-columns: 2fr 1fr;
  }
  .two-column {
    grid-template-columns: 1fr 1fr;
  }
  .three-column {
    grid-template-columns: 1fr 1fr 1fr;

  }
  .feeds-matches {
    padding: 10px 0px;
    /* border: 1px solid #f7941e; */
    margin: 10px 0px;
  }
  .feeds-matches p {
    margin-bottom: 0px;
  }
  .feeds-matches .teams p {
    font-weight: 500;
  }
  .league-schedule {
    font-size: 11px;
    color: var(--a-grey);
  }
  .more-markets {
    /* color: var(--bg-orange); */
    /* margin-bottom: 8px; */
    cursor: pointer;
  }
  .feed-schedule {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3px;
  }
  .feed-schedule p {
    margin-bottom: 0px !important;
  }
</style>