<template>
  <div v-frag>
    <div class="nav-section hidden-xs hidden-sm">
        <div class="nav-section-links">
          <router-link to = "/" class="nav-item"> {{ $t('header.sport') }} </router-link>
          <router-link  :to=" getLiveLink()" class="nav-item">
            {{ $t('header.live') }} 
            <template v-if="liveCount">
              ({{ liveCount.total }})
            </template>
          </router-link>
          <router-link to="/aviatrix" class="nav-item">{{ $t('casino.aviator') }} <sup class="badge badge-danger">NEW</sup></router-link>
          <router-link to = "/casino-home" class="nav-item">{{ $t('casino.casino') }}</router-link>
          <router-link to = "/jetx" class="nav-item">JetX <sup class="badge badge-danger">NEW</sup></router-link>
          <router-link to = "/crash-games" class="nav-item">{{ $t('casino.crash') }} <sup class="badge badge-danger">NEW</sup></router-link>
          <a @click.prevent="showPrintGames" class="nav-item"> {{ $t('feeds.download-pdf') }}</a>
          <router-link  to="/results" class="nav-item">{{ $t('header.results') }}</router-link>
          <router-link to = "/invite-friend" class="nav-item">{{ $t('header.invite-a-friend-h') }}</router-link>
          <router-link to = "/promotions" class="nav-item">{{ $t('header.promotions') }}</router-link>
          <router-link to = "/app" class="nav-item">{{ $t('header.app') }}</router-link>
        </div>
        <div class="">
          <template>
            <i class="fa fa-clock-o" aria-hidden="true"></i> <digital-clock :displaySeconds="true"/>
          </template>
          <lang />
          <router-link  to="/messages" class="nav-item">
            <i class="icofont-ui-messaging"></i>
            <span>{{ $t('header.messages') }} <sup v-if="loggedIn && msg >0" class="badge btn-danger">{{ msg }}</sup></span>
          </router-link>
          <!-- <a href="" class="nav-item"> {{ $t('betslip.search') }}</a> -->
        </div>
    </div>
    <div class="mobile-nav-section visible-xs visible-sm">
      <div class="download-app" @click="toApp()" v-if="currentRouteName != 'app'">
        <span>{{ $t('header.live-dw') }}!!</span>
        <i class="icofont-brand-android-robot"></i>
        <i class="icofont-brand-apple"></i> 

      </div>
      <div class="mobile-nav">
        <a @click.prevent="getUpcomingGames(10)" :class="{'text-orange' :  markets.sportId == 10 }" v-if="currentRouteName != 'live' && currentRouteName != 'single-live-match'" >
          <i class="icofont-football-alt"></i>
          <span>{{ $t('sidebar.football' )  }}</span>
        </a>
        <a @click.prevent="getLiveGames({ name:'Soccer',sport:1})" :class="{'text-orange' : sport.str == $route.params.sport }" v-if="currentRouteName == 'live' || currentRouteName == 'single-live-match'" >
          <i class="icofont-football-alt"></i>
          <span>{{ $t('sidebar.football' )  }}</span>
        </a>
        <router-link to="/casino-home">
          <i class="icofont-canoe"></i> 
          <span>{{ $t('casino.casino') }}</span>
        </router-link>
        <router-link to="/jetx">
          <i class="icofont-airplane"></i> 
          <span>JetX <sup class="badge badge-danger">new</sup></span>
        </router-link>
        <router-link to="/crash-games">
          <i class="icofont-dashboard"></i> 
          <span>{{ $t('casino.crash') }} <sup class="badge badge-danger">new</sup></span>
        </router-link>
        
        <router-link to="/aviatrix">
          <i class="fa fa-plane" aria-hidden="true"></i>
          <span>{{ $t('casino.aviator') }} <sup class="badge badge-danger">new</sup></span>
        </router-link>
        <router-link to="/promotions">
          <i class="icofont-badge"></i> 
          <span>{{ $t('header.promotions') }}</span>
        </router-link>
        <router-link  to="/invite-friend">
          <i class="icofont-users"></i> 
          <span>{{ $t('header.invite-a-friend-h') }}</span>
        </router-link>
        
        <router-link  to="/results">
          <i class="icofont-listing-box"></i> 
          <span>{{ $t('header.results') }}</span>
        </router-link>
        <router-link  to="/messages" class="nav-item">
          <i class="icofont-ui-messaging"></i>
          <span>{{ $t('header.messages') }} <sup v-if="loggedIn && msg >0" class="badge badge-danger">{{ msg }}</sup></span>
        </router-link>
        <template v-if="currentRouteName != 'live' && currentRouteName != 'single-live-match'">
          <div v-frag  v-for="(sport,i) in menu" :key="'nsp-'+i"  >
            <a @click.prevent="getUpcomingGames(sport.id)" v-if="sport.id != 10" :class="{'text-orange' : markets.sportId == sport.id}">
              <i class="icofont-kick" v-if="sport.id == 491393"></i>
              <!-- Soccer -->
              <i class="icofont-basketball" v-if="sport.id == 4"></i>
              <i class="icofont-dart" v-if="sport.id == 8"></i>
              <!-- basketball -->
              <i class="icofont-baseball" v-if="sport.id == 3"></i>
              <!-- baseball -->
              <i class="icofont-hockey" v-if="sport.id == 15"></i>
              <!-- ice hockey -->
              <i class="icofont-tennis" v-if="sport.id == 24"></i>
              <i class="icofont-table-tennis" v-if="sport.id == 269467" ></i>
              <i class="icofont-jumping" v-if="sport.id == 2 "></i>
              <!-- tennis -->
              <i class="demo-icon icon-handball" v-if="sport.id == 99614">&#xe825;</i>
              <!-- handball -->
              <!-- <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe832;</i> -->
              <!-- boxing -->
              <i class="icofont-motor-biker" v-if="sport.id == 16"></i>
              <!-- motor-bike -->
              <i class="icofont-rugby" v-if="sport.id == 73744 || sport.id == 73743"></i>
              <!-- rugby -->
              <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe802;</i>
              <!-- cricket -->
              <i class="icofont-volleyball" v-if="sport.id == 91189"></i>
              <!-- volleyball -->
              <i class="icofont-rugby-player" v-if="sport.id == 17"></i>          
              <i class="icofont-billiard-ball" v-if="sport.id == 22"></i>         
              <span>{{ $t('sidebar.' + sport.en_name )  }}</span>
            </a>
          </div>
        </template>

        <template v-if="currentRouteName == 'live' || currentRouteName == 'single-live-match'">
          <div v-frag  v-for="(sport,i) in liveMenu" :key="'nspg-'+i" >
            <a @click.prevent="getLiveGames(sport)" v-if="sport.id != 1" :class="{'text-orange' : sport.str == $route.params.sport }">
              <i class="icofont-kick" v-if="sport.id == 491393"></i>
              <!-- Soccer -->
              <i class="icofont-basketball" v-if="sport.id == 2"></i>
              <i class="icofont-dart" v-if="sport.id == 8"></i>
              <!-- basketball -->
              <i class="icofont-baseball" v-if="sport.id == 3"></i>
              <!-- baseball -->
              <i class="icofont-hockey" v-if="sport.id == 4"></i>
              <i class="icofont-table-tennis" v-if="sport.id == 20" ></i>
              <!-- ice hockey -->
              <i class="icofont-tennis" v-if="sport.id == 5"></i>
              <i class="icofont-jumping" v-if="sport.id == 34 "></i>
              <!-- tennis -->
              <i class="demo-icon icon-handball" v-if="sport.id == 6">&#xe825;</i>
              <!-- handball -->
              <!-- <i class="demo-icon icon-cricket" v-if="sport.id == 6">&#xe832;</i> -->
              <!-- boxing -->
              <i class="icofont-motor-biker" v-if="sport.id == 16"></i>
              <!-- motor-bike -->
              <i class="icofont-rugby" v-if="sport.id == 73744 || sport.id == 73743"></i>
              <!-- rugby -->
              <i class="demo-icon icon-cricket" v-if="sport.id == 6999">&#xe802;</i>
              <!-- cricket -->
              <i class="icofont-volleyball" v-if="sport.id == 91189"></i>
              <!-- volleyball -->
              <i class="icofont-rugby-player" v-if="sport.id == 17"></i>          
              <i class="icofont-billiard-ball" v-if="sport.id == 22"></i>         
              <span v-if="$i18n.locale == 'en'">{{ sport.name }}</span>
              <span v-if="$i18n.locale == 'fr'">{{ sport.frName }}</span>
            </a>
          </div>
        </template>


      </div>
    </div>
    <modal name="printGames" :width="400" height="auto" :adaptive="true">
      <h5 class="text-center dep"> {{ $t('feeds.download-pdf') }}</h5>
      <div class="form-group pad">
        <label class="radio-inline" v-if="sport == 10"><input type="radio" name="optradio" v-model="category" value="featured">{{ $t('feeds.featured-games') }}</label>
        <label class="radio-inline"><input type="radio" name="optradio" v-model="category" value="today" >{{ $t('feeds.today') }}</label>
        <label class="radio-inline"><input type="radio" name="optradio" v-model="category" value="upcoming">{{ $t('feeds.upcoming') }}</label>
      </div>
      <div class="form-group pad">
        <label for="">{{ $t('header.sport') }}</label>
        <select v-model="sport" class="form-control">
          <option v-for="(sport,i) in menu"  :key="'spn-'+i" :value="sport.id" ><span>{{ $t('sidebar.' + sport.en_name )  }}</span></option>
        </select>
      </div>
      
      <div class="text-center flexx">
        <button class="btn btn-danger btn-sm" @click="cancelPrintGames">{{ $t('betslip.cancel') }}</button>
        <button class="btn btn-primary btn-sm" @click="printGames">
          {{ $t('feeds.download-pdf') }}
          <template v-if="loading">
            <img :src="`${publicPath}images/spinner.gif`" alt="" style="width: 20px;height: 20px;">
          </template>
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ApiService from "@/services/api";
import { getCountryISO2 } from "@/utils/utility";
import { getSupportedLocales } from "@/utils/supported-locales";
import lang from '@/components/sections/nav-sections/locale-switcher.vue';
import DigitalClock from "vue-digital-clock";

export default {
  computed:{
    ...mapGetters({
      menu: 'menu',
      markets: 'markets',
      msg: 'msg',
      loggedIn: 'isAuthenticated',
      liveCount:'liveCount',
      liveMenu: 'liveMenu',
      live_markets:'live_markets'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    market() {
      return this.live_markets.find( x=> x.sportId == this.spid);
    },
  },
  components:{
    lang,
    DigitalClock
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      locales: getSupportedLocales(),
      category: 'today',
      loading: false,
      sport:10,
      spid:1
    }
  },
  async created() {
    await this.$store.dispatch('get_live_count');
    setInterval( async() => {
      await this.$store.dispatch('get_live_count')
    },15000)
    
  },
  mounted(){
    this.$store.dispatch("fetch_sidebar_menu");
    this.loadMessages();
  },
  methods: {
    getUpcomingGames: function (id) {  
      let url = "/sport/" + id
      if (this.$route.path != url ) {
          this.$router.push(url)
      }
    },
    loadMessages: async function() {
      await this.$store.dispatch("set_messages",{
          post:{
              "page":0,
              "size":15
          },
          type:'new'
      });
    },
    printGames: async function() {
      this.loading = true;
      const doc = new jsPDF('l', 'pt', 'a4');
      var col = null
      let regex = /[\s)(&/]+/g;

      this.$store.dispatch("get_markets",{
        id:this.sport
      });

      if (this.$i18n.locale == 'en') {
        if(this.sport == 10) {
          col = ["Id", "Jackpot Games", "Sport", "Country", "League", "Game ID", "Schedule", "1", "X", "2", "1 0R X", "1 OR 2", "X OR 2"];
        } else {
          col = ["Id", "Jackpot Games", "Sport", "Country", "League", "Game ID", "Schedule"];

          this.markets.markets.map( x => {
            if(x.print) {
              x.selections.map( ar => col.push(ar.name))
            }
          })
        }
      } else {
        if(this.sport == 10) {
          col = ["Id", "les jeux de Jackpot", "Sport", "Pays", "Ligue", "ID du jeu", "Programmer", "1", "X", "2", "1 OU X", "1 OU 2", "X OU 2"];
        } else {
          col = ["Id", "les jeux de Jackpot", "Sport", "Pays", "Ligue", "ID du jeu", "Programmer"];
          this.markets.markets.map( x => {
            if(x.print) {
              x.selections.map( ar => col.push(ar.shortname))
            }
          })
        }
      }

      var rows = []
      let x = 1

      ApiService.getPrintableGames({ category:this.category,sport:this.sport}).then( data => {
       
        this.loading = false;
        let printmarkets = [];
        this.markets.markets.map( x => {
          if(x.print) {
            printmarkets.push(x.id)
          }
        });
        
        data.forEach(el => {
          let tmp = [];
          tmp[0] = x;

          let strs = el.sportName.trim().toLowerCase()
          let sname = strs.replace(regex,"-");

          if (this.$i18n.locale == 'en') {
              tmp[1] = el.home + '-' + el.away
              tmp[2] = this.$i18n.t('sidebar.' + sname )
              tmp[3] = (getCountryISO2(el.country,'sidebar')) ? getCountryISO2(el.country,'sidebar').name : el.country
              tmp[4] = el.tournament
          } else {
              tmp[1] = el.home + '-' + el.away
              tmp[2] = this.$i18n.t('sidebar.' + sname )
              tmp[3] = (getCountryISO2(el.country,'sidebar')) ? getCountryISO2(el.country,'sidebar').fr_name : el.country
              tmp[4] = el.tournament;
          }

          tmp[5] = el.id;
          tmp[6] = el.scheduled;

          let y = 7;

          if(this.sport == 10) {
            let y_ = el.markets.find( x => x.marketId == 2)
            if (y_) {
                y_.odds.forEach( od => {
                    tmp[y] = od.odds;
                    y++;
                })
            }

            let z = 10;
            let z_ =  el.markets.find( x => x.marketId == 7202)
            if (z_) {
                z_.odds.forEach( od => {
                  tmp[z] = od.odds;
                  z++;
              })
            }
          } else {
            let y_ = el.markets.find( x => printmarkets.includes(Number(x.marketId)))
            if (y_) {
                y_.odds.forEach( od => {
                    tmp[y] = od.odds;
                    y++;
                })
            }

          }
                              
          
          x++;
          rows.push(tmp);
        })

        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        var base64Img = ApiService.getLogo();
        var pageContent = function (data) {
          // HEADER

          if (base64Img) {
              doc.addImage(base64Img, 'PNG', (pageWidth / 2) - 50, 10, 160, 30);
          }
          
          // FOOTER
          var str_ =  "" + data.pageCount
          var str = ""
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
              str = str_;
          }
          doc.setFontSize(8)
          doc.text(str, pageWidth / 2, pageHeight - 15, 'center');
        };

        doc.autoTable({
          head: [col],
          body: rows,
          theme: 'grid',
          styles: { fontSize: 7, margin: 10 },
          headStyles: { fillColor: [7, 59, 107], textColor: [255, 255, 255], halign: 'center', valign: 'middle' },
          bodyStyles: { halign: 'left' },
          margin: { top: 50 },
          showHead: 'firstPage',
          didDrawPage: pageContent
        });

        doc.save('rahisibet.pdf');
        this.$modal.hide("printGames");
        this.category = 'today';
          
      });
    },
    showPrintGames: function() {
      this.$modal.show('printGames')
    },
    cancelPrintGames: function() {
      this.$modal.hide("printGames");
      this.category = 'today';
      this.sport = '';
    },
    useLanguage: function(lang) {
      this.$i18n.locale = lang
    },
    iso: function (code) {
      const res = getCountryISO2(code,'ls')
      if(res) {
          return res
      } else {
          return 'DEFAULT'
      }
    },
    getLiveGames: function(sport) {
      let route = sport.name.trim();
      let str = route.replace(/\s/g, '')

      if(this.$route.params.sport != str) {
        this.$router.push(`/live/${str}`)
      }
    },
    getLiveLink: function() {
      if(this.liveMenu.length > 0) {
        let url = this.liveMenu.find( x => x.id == 1);
        if(url) {
          return '/live/Soccer';
        } else {
          let url = this.liveMenu.find( (x,i) => i==0);
          let str = url.name.replace(/\s/g, '')
          return `/live/${str}`;
        }
      } else {
        return '/live/Soccer';
      }
    },
    toApp: function() {
      this.$router.push(`/app`)
    },
    soso: function() {

    }
  }
}
</script>
<style scoped>
  .vm--container {
    color :#000000
  }
  .vm--container .dep {
    font-weight: bold;
    color: #595959;
    font-size: 1.5em;
  }
  .vm--container .pad {
    padding: 10px;
  }
  .vm--container .flexx {
    display: flex;
    justify-content: space-around;
    padding: 0px 10px 10px;
    gap: 10px;
  }
  .vm--container .flexx .btn {
    flex: 1 1 0;
  }
  sup.badge {
    background-color: red !important;
    font-style: italic;
    font-size: 10px;
  }
  .download-app {
    padding: 5px 10px;
    background-color: var(--bg-orange);
    font-weight: 500;
  }
  .download-app span {
    margin-right: 15px;
    display: inline-block;
  }
</style>