<template>
  <div class="custom-modal custom-mask">
    <div class="modal-container auto">   
      <button class="modal-x" @click="$emit('close')">
        <i class="icofont-close"></i>
      </button>  
      <div class="custom-body-modal">
        <slot></slot>
        <p class="text-center" @click="$emit('close')">{{ $t('swal.cancel') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    selectMarket: function(id) {
      this.$emit('selectMarket',id);
    },
    selectType(type,arg) {
      this.$emit('selectType',type,arg);
    },
  }
}
</script>
<style scoped>
.custom-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  transition: opacity .3s ease;
  /* max-width: var(--app-width); */
  margin: 0 auto;
  cursor: pointer;
}
.custom-modal {
  /* text-align: center; */
  z-index: 999999999999;
  
}
.custom-body-modal {
  padding: 10px;
  max-height: 60vh;
  overflow-y: auto;
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  background-color: var(--bg-dark-medium);
  transition: all .3s ease;
  transform: translate(-50%,-50%);
  color: var(--light-black);
  margin-top: 0 !important;
  letter-spacing: .15px;
  /* background: var(--white); */
  box-shadow: 0 5px 7px rgba(0,0,0,.1);
  border-radius: 3px;
  padding: 10px;
  cursor: auto;
  padding-top: 10px;
}

.custom-body-modal span.title {
  font-size: 18px;
  font-weight: bold;
}
.custom-body-modal p {
  font-weight: bold;
  cursor: pointer;
}
.modal-x {
  /* position: absolute; */
  color: var(--bg-orange);
  left: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
  line-height: 31px;
  font-size: 30px;
  border: none;
  cursor: pointer;
  background: transparent;
  z-index: 999999999999;
}
.match-filter-container {
    padding: 10px;
    max-height: 60vh;
    overflow-y: auto;
}
</style>