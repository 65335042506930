import Vue from 'vue';
import App from './App.vue';
import store from "./store/store.js";
import router from "./routes/routes.js";
import Vuelidate from 'vuelidate';
import frag from 'vue-frag';
import VueSweetalert2 from 'vue-sweetalert2';
import IdleVue from "idle-vue";
import VuePaginate from 'vue-paginate';
import VModal from 'vue-js-modal';
import moment from 'moment';
import VueMoment from 'vue-moment';
import interceptor from "@/utils/interceptors";
import VueProgressBar from 'vue-progressbar';
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from 'vue-clipboard2';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueGtm from '@gtm-support/vue2-gtm';
import Popover from 'vue-js-popover'

 
Vue.use(Popover)

Vue.use(VueTelInput);

// Choose Locale
moment.locale('en');
Vue.use(VueMoment, { moment });

interceptor();
const eventsHub = new Vue();

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.directive('frag', frag);
Vue.use(VueSweetalert2);
Vue.use(VuePaginate)
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 7200000,
    startAtIdle: false
})
Vue.use(VModal);
Vue.prototype.moment = moment;
Vue.use(VueProgressBar, {
    color: 'var(--bg-orange)',
    failedColor: 'red',
    height: '2px'
});
Vue.use(VueSocialSharing);
Vue.use(VueClipboard);
Vue.use(VueTelInput);

Vue.use(VueGtm, {
  id: 'GTM-PZKMS5QB', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

import sidebar from '@/components/New/partials/main-sidebar.vue';
import betslip from '@/components/New/partials/betslip.vue';
import market from '@/components/New/sections/feeds/market.vue';
import match from '@/components/New/sections/feeds/match.vue';
import Carousel from "@/components/Pages/games/sections/carousel.vue";
import Loader from "@/components/sections/loader.vue";

Vue.component('the-sidebar',sidebar);
Vue.component('the-betslip',betslip);
Vue.component('the-market',market);
Vue.component('the-match',match);
Vue.component('the-carousel',Carousel);
Vue.component('loader',Loader);


import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.css';
import './assets/css/custom.css';
import "flag-icon-css/css/flag-icon.min.css"


import 'sweetalert2/dist/sweetalert2.min.css'
import i18n from './i18n'

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
