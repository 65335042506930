const Login = () => import("@/components/Pages/authentication/Login.vue");
const Register = () => import("@/components/Pages/authentication/Register.vue");
const Resetpassword = () =>  import("@/components/Pages/authentication/Reset-password.vue");
const Verification = () =>  import("@/components/Pages/authentication/Verification.vue");
const Forgotpassword = () =>  import("@/components/Pages/authentication/Forgot-password.vue");
const Howtoplay = () => import("@/components/Pages/links/How-to-play.vue");
const Responsiblegaming = () =>  import("@/components/Pages/links/Responsible-gaming.vue");
const Terms = () => import("@/components/Pages/links/Terms-and-conditions.vue");
const CasinoHome = () => import("@/components/Pages/casino/home.casino.vue");
const LiveCasino = () => import("@/components/Pages/casino/live.casino.vue");
const Lobby = () => import("@/components/Pages/casino/lobby.casino.vue");
const CasinoBets = () => import("@/components/Pages/casino/casino.bets.vue");
const SlotRender = () => import("@/components/Pages/casino/slot.renderer.vue");
const CrashGames = () => import("@/components/Pages/casino/crash.games.vue");
const LiveRenderer = () => import("@/components/Pages/casino/live.renderer.vue");
const DemoRenderer = () => import("@/components/Pages/casino/demo.renderer.vue");
const NewHome = () => import("@/components/New/pages/home.vue");
const Results = () => import("@/components/New/pages/results.vue");
const Betslip = () => import("@/components/New/partials/betslip.vue");
const Promotions = () => import("@/components/New/pages/promotions.vue");
const Live = () => import("@/components/New/pages/live.vue");
const Leaderboard = () => import("@/components/New/pages/leaderboard.vue");
const Error = () => import("@/components/New/pages/error.vue");
const AML = () => import("@/components/New/pages/amlpolicy.vue");
const Messages = () => import("@/components/New/pages/messages.vue");
const InviteFriend = () => import("@/components/New/pages/invitefriend.vue");
const App = () => import("@/components/New/pages/app.vue");
const AviatorRenderer = () => import("@/components/Pages/casino/casino/demo.aviatrix.vue");
const JetxDemoRenderer = () => import("@/components/Pages/casino/casino/demo.jetx.vue");
const JetxLiveRenderer = () => import("@/components/Pages/casino/casino/live.jetx.vue");
const Jetx = () => import("@/components/Pages/casino/home.jetx.vue");
const LiveAviatorRenderer = () => import("@/components/Pages/casino/casino/live.aviatrix.vue");
const HomeAviator = () => import("@/components/Pages/casino/home.aviatrix.vue");

import store from "@/store/store"
import Vue from "vue";
import Router from "vue-router";
import Auth from "@/services/auth";

Vue.use(Router);
	const DEFAULT_TITLE = "Rahisibet";
	const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/login",
			name: "login",
			component: Login,			
			meta: { guest: true,title: DEFAULT_TITLE + " - Login", },
		},
		{
			path: "/register",
			name: "register",
			component: Register,
			meta: { guest: true, title: DEFAULT_TITLE + " - Register", },
			
		},
		{
			path: "/",
			name: "home",
			component: NewHome,
			meta: { title: "Rahisibet | Africa's Leading Betting House" },
		},
		{
			path: "/betslip",
			name: "betslip",
			component: Betslip,
			meta: { title: DEFAULT_TITLE },
		},
		{
			path: "/results",
			name: "results",
			component: Results,
			meta: { title: DEFAULT_TITLE + ' - Results' },
		},
		{
			path: "/messages",
			name: "messages",
			component: Messages,
			meta: { requiresAuth: true,title: DEFAULT_TITLE + ' - Messages' },
		},
		{
			path: "/sport/:id",
			name: "sport",
			component: NewHome,
			meta: { title: DEFAULT_TITLE + " - Sport" },
		},
		{
			path: "/shared-slip/:slip",
			name: "shared-slip",
			component: NewHome,
			meta: { title: DEFAULT_TITLE + " - Sport" },
		},
		{
			path: "/sport/:sport/league/:id",
			name: "league",
			component: NewHome,
			meta: { title: DEFAULT_TITLE + " - League" },
		},
		{
			path: "/reset-password",
			name: "reset-password",
			component: Resetpassword,
			meta: { guest: true, title: DEFAULT_TITLE + " - Reset-Password", },
		},
		{
			path: "/verification",
			name: "verification",
			component: Verification,
			meta: { guest: true, title: DEFAULT_TITLE + " - Verification" },
		},
		{
			path: "/forgot-password",
			name: "forgot-password",
			component: Forgotpassword,
			meta: { guest: true,title: DEFAULT_TITLE + " - Forgot-Password", },
			
		},
		{
			path: "/how-to-play",
			name: "how-to-play",
			component: Howtoplay,
			meta: { title: DEFAULT_TITLE + " - How to Play" },
		},
		{
			path: "/responsible-gaming",
			name: "responsible-gaming",
			component: Responsiblegaming,
			meta: { title: DEFAULT_TITLE + " - Responsible Gaming" },
		},
		{
			path: "/aml-policy",
			name: "aml-policy",
			component: AML,
			meta: { title: DEFAULT_TITLE + " - AML Policy" },
		},
		{
			path: "/app",
			name: "app",
			component: App,
			meta: { title: DEFAULT_TITLE + " - APP" },
		},
		{
			path: "/terms-and-conditions",
			name: "terms-and-conditions",
			component: Terms,
			meta: {  title: DEFAULT_TITLE + " - Terms n Conditions" },
		},
		{
			path: "/jackpots",
			name: "jackpots",
			component: () => import("@/components/New/pages/jackpots.vue"),
			meta: { title: DEFAULT_TITLE + " - Mega Jackpot" },
		},
		{
			path: "/:sportId/single-match/:id",
			name: "single-match",
			component: () => import("@/components/New/pages/moregames.vue"),
			meta: { title: DEFAULT_TITLE + " - Single Match" },
		},
		{
			path: "/:sportId/single-live-match/:id",
			name: "single-live-match",
			component: () => import("@/components/New/pages/livegame.vue"),
			meta: { title: DEFAULT_TITLE + " - Single Live Match" },
		},
		{
			path: "/profile",
			name: "profile",
			meta: { requiresAuth: true,title: DEFAULT_TITLE + " - Profile", },
			component: () => import("@/components/New/pages/profile.vue"),
		},
		{
			path: "/all-bets",
			name: "all-bets",
			meta: { requiresAuth: true,title: DEFAULT_TITLE + " - All Bets", },
			component: () => import("@/components/New/pages/mybets.vue"),
		},
		{
			path: "/single-bet/:id",
			name: "single-bet",
			meta: { requiresAuth: true, title: DEFAULT_TITLE + " - Single Bet", },
			component: () => import("@/components/New/pages/bets.vue"),
		},
		{
			path: "/casino",
			name: "casino",
			meta: { title: DEFAULT_TITLE + " - Casino", },
			component: CasinoHome,
		},
		{
			path: "/promotions",
			name: "promotions",
			meta: { title: DEFAULT_TITLE + " - Promotions", },
			component: Promotions,
		},
		{
			path: "/crash-games",
			name: "crash-games",
			meta: { title: DEFAULT_TITLE + " - Crash Games", },
			component: CrashGames,
		},
		{
			path: "/live/:sport",
			name: "live",
			meta: { title: DEFAULT_TITLE + " - Live", },
			component: Live,
		},
		{
			path: "/leaderboard",
			name: "leaderboard",
			meta: { title: DEFAULT_TITLE + " - Promotions", },
			component: Leaderboard,
		},
		{
			path: "/casino-home",
			name: "casino-home",
			meta: { type:'casino', title: DEFAULT_TITLE + " - Casino Home", },
			component: CasinoHome,
		},
		{
			path: "/casino-live",
			name: "casino-live",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Live Casino", },
			component: LiveCasino,
		},
		{
			path: "/casino-live/lobby",
			name: "casino-lobby",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Live Lobby Casino", requiresAuth: true },
			component: Lobby,
		},
		{
			path: "/casino/bets",
			name: "casino-bets",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Live Lobby Casino", requiresAuth: true,},
			component: CasinoBets,
		},
		{
			path:"/casino/slots/:gameId",
			name:"slot_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Casino", requiresAuth: true,slot:"render"},
			component:SlotRender

		},
		{
			path:"/casino/live/lobby/:gameId",
			name:"live_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Casino", requiresAuth: true,slot:"render"},
			component:LiveRenderer

		},
		{
			path:"/casino/slots/demo/:gameId",
			name:"demo_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Casino", requiresAuth: true,slot:"render"},
			component:DemoRenderer
		},
		{
			path:"/aviatrix",
			name:"aviatrix",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Aviatrix",slot:"render"},
			component:HomeAviator,
		},
		{
			path:"/casino/demo_aviatrix",
			name:"demo_aviatrix_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Aviatrix",slot:"render"},
			component:AviatorRenderer,
		},
		{
			path:"/live_jetx",
			name:"live_jetx_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - JetX",slot:"render",requiresAuth: true},
			component:JetxLiveRenderer,
		},
		{
			path:"/invite-friend",
			name:"invite-friend",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Invite Friend",slot:"render",requiresAuth: true},
			component:InviteFriend,
		},
		{
			path:"/demo_jetx",
			name:"demo_jetx_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - JetX",slot:"render"},
			component:JetxDemoRenderer,
		},
		{
			path:"/jetx",
			name:"jetx_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - JetX",slot:"render"},
			component:Jetx,
		},
		{
			path:"/casino/live_aviatrix",
			name:"live_aviatrix_renderer",
			meta: { type:'casino',title: DEFAULT_TITLE + " - Aviatrix",slot:"render",requiresAuth: true},
			component:LiveAviatorRenderer,
		},
		{
			path: "/:notFound(.*)",
			name:"404",
			meta: { guest: true, title: DEFAULT_TITLE + " - 404", },
			component: Error
		}
	],
	scrollBehavior() {
		window.scrollTo(0,0);
        // document.getElementById('app').scrollIntoView();
    },
	linkExactActiveClass: "selected",
});

router.beforeEach((to, from, next) => {
	Vue.nextTick(() => {
		document.title = to.meta.title;
	});

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (Auth.isLoggedIn()) {
		next();
		return;
		}
		next({ path: "/login", query: { redirect: to.fullPath } });
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {

	if(to.meta.slot == 'render') {
		store.dispatch('set_casino',true)
	} else {
		store.dispatch('set_casino',false)
	}

	if (to.matched.some((record) => record.meta.guest)) {
			
		if (Auth.isLoggedIn()) {
		next("/");
		return;
		}
		next();
	} else {
		next();
	}
});

export default router;
