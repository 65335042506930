export const getters = {
    user: state => { 
        if ( state.authUser) {
            return state.authUser.user
        } else {
            return null
        }   
    },
    account: state => {
        if ( state.authUser) {
            return state.authUser.user.account
        } else {
            return []
        }
    },
    profile: state => {
        if ( state.authUser) {
            return state.authUser.user.profile
        } else {
            return []
        }
    },
    token: state => {
        if ( state.authUser) {
            return state.authUser.token
        } else {
            return null
        }
    },
    bets: state => state.betHistory,
    matches: state => state.betMatches,
    bet: state => state.singleBet,
    isAuthenticated: state => !!state.authUser,
    authStatus: state => state.status,
    leaderboard: state => state.leaderboard,
    sharedBet: state => state.sharedBet,
    sharedBetError: state => state.sharedBetError,
    msg: state => state.messages.filter( x => x.status == false).length,
    rates: state => state.rates,
    geo: state => state.geo,
    messages: state => state.messages
}