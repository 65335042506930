export const markets = [
    {
        "sportId": 10,
        "en_name": "Soccer",
        "fr_name": "Football",
        "displayId":"match-result",
        "markets": [
            {
                "id": 2,
                "specValue": 0,
                "name": "1x2",
                "fr_name": "1x2",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                "key": "match-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 7202,
                "specValue": 0,
                "name": "Double chance",
                "fr_name": "Double chance",
                "bg": "Double Chance ",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id":"7"
                    },
                    {
                        "name": "X 0R 2",
                        "shortName": "X OU 2",
                        "id":"9"
                    },
                    {
                        "name": "1 OR 2",
                        "shortName": "1 OU 2",
                        "id":"8"
                    }
                    
                ]
            },
            {
                "id": 259,
                "specValue": 0,
                "name": "under/over 2.5",
                "fr_name": "Moins de/Plus de 2.5",
                "bg": "Over/Under 2.50",
                "columns": 2,
                "inColumn": 3,
                "key": "over-under-+2.5-2.50",
                "selections": [
                    {
                        "name": "under",
                        "shortName": "Moins de",
                        "id":"31"
                    },
                    {
                        "name": "over",
                        "shortName": "Plus de",
                        "id":"30"
                    }
                ]
            },
            {
                "id": 7079,
                "specValue": 0,
                "name": "GG/NG",
                "fr_name": "GG/NG",
                "bg": "Over/Under 2.50",
                "columns": 2,
                "inColumn": 3,
                "key": "both-teams-to-score",
                "selections": [
                    {
                        "name": "Yes",
                        "shortName": "Oui",
                        "id":"39"
                    },
                    {
                        "name": "No",
                        "shortName": "non",
                        "id":"40"
                    }
                ]
            },
            {
                "id": 6832,
                "specValue": 0,
                "name": "1x2 First half",
                "fr_name": "1x2 Mi-temps",
                "bg":"1x2 First half",
                "columns": 3,
                "inColumn": 1,
                "key": "half-time-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 7591,
                "specValue": 0,
                "name": "1x2 Second half ",
                "fr_name": "1x2 2ème Mi-temps",
                "bg":"1x2 Second half",
                "columns": 3,
                "inColumn": 1,
                "key": "second-half-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
        ]
    },
    {
        "sportId": 4,
        "en_name": "Basketball",
        "fr_name": "Basketball",
        "displayId":"match-result-excluding-overtime-",
        "markets": [
            {
                "id": 306,
                "specValue": 0,
                "name": "Match Result (Excluding Overtime)",
                "fr_name":"LIGNE D'ARGENT",
                "columns": 3,
                "inColumn": 3,
                "key": "match-result-excluding-overtime-",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 115,
                "specValue": 0,
                "name": "Money Line",
                "fr_name":"LIGNE D'ARGENT",
                "columns": 2,
                "inColumn": 2,
                "key": "money-line",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
           
        ]
    },
    {
        "sportId": 3,
        "en_name": "Baseball",
        "fr_name": "Baseball",
        "displayId":"money-line",
        "markets": [
            {
                "id": 117,
                "specValue": 0,
                "name": "Money Line",
                "fr_name":"Money Line",
                "columns": 2,
                "inColumn": 2,
                "key": "money-line",
                "print":true,
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":1
                    },
                    {
                        "name": "2",
                        "shortName": "3",
                        "id": 3
                    }
                ]
            },
            {
                "id": 7754,
                "specValue": 0,
                "name": "1x2",
                "fr_name": "RESULTAT DU MATCH",
                "columns": 3,
                "inColumn": 1,
                
                "key": "match-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
  
        ]
    },
    {
        "sportId": 15,
        "en_name": "Ice Hockey",
        "fr_name": "Hockey sur glace",
        "displayId":"60-minutes-betting",
        "markets": [
            {
            "id": 202,
            "specValue": 0,
            "name": "1x2(60 Minutes Betting)",
            "fr_name" : "1x2(Pari sur 60 minutes)",
            "columns": 3,
            "print":true,
            "inColumn": 1,
            "key": "60-minutes-betting",
            "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id": 1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id": 2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id": 3
                }
            ]
            },
            {
                "id": 6713,
                "specValue": 0,
                "name": "Double chance",
                "fr_name": "Double chance",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id": 7
                    },
                    {
                        "name": "X OR 2",
                        "shortName": "X OU 2",
                        "id": 9
                    },
                    {
                        "name": "1 0R 2",
                        "shortName": "1 OU 2",
                        "id": 8
                    }
                ]
            },
            {
                "id": 183,
                "name": "Money Line",
                "fr_name": "LIGNE D'ARGENT",
                "columns": 2,
                "inColumn": 3,
                "key": "money-line",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id": 1
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id": 3
                    }
                ]
            }
        ]
    },
    {
        "sportId": 24,
        "en_name": "Tennis",
        "fr_name": "Tennis",
        "displayId":"first-set-winner",
        "markets": [
            {
                "id": 77,
                "specValue": 0,
                "name": "First Set Winner",
                "fr_name": "First Set Winner",
                "columns": 2,
                "inColumn": 1,
                "print":true,
                "key": "first-set-winner",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : "25"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : "26"
                    }
                ]
            },
            {
                "id": 322,
                "specValue": 0,
                "name": "1x2",
                "fr_name": "1x2",
                "columns": 2,
                "inColumn": 1,
                "print":true,
                "key": "match-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : "25"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : "26"
                    }
                ]
            },
            {
                "id": 6930,
                "specValue": 0,
                "name": "Doubles Match",
                "fr_name": "MATCH DE DOUBLE",
                "columns": 2,
                "inColumn": 1,
                "key": "doubles-match",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id" : "12"
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id" : "13"
                }
                ]
            },
            {
            "id": 6481,
            "specValue": 0,
            "name": "Total Games Over/Under",
            "fr_name": "TOTAL DES JEUX PLUS DE/MOINS DE",
            "columns": 2,
            "inColumn": 1,
            "key": "total-games-over-under",
            "selections": [
            {
                "name": "1",
                "shortName": "1",
                "id" : "12"
            },
            {
                "name": "2",
                "shortName": "2",
                "id" : "13"
            }
            ]
        }
        ]
    },
    {
        "sportId": 269467,
        "en_name": "Table Tennis",
        "fr_name": "Tennis de table",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 6750,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 2,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : "25"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : "26"
                    }
                ]
            },
            {
                "id": 6930,
                "specValue": 0,
                "name": "Doubles Match",
                "fr_name": "MATCH DE DOUBLE",
                "columns": 2,
                "inColumn": 1,
                "key": "doubles-match",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id" : "12"
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id" : "13"
                }
                ]
            },
            {
            "id": 6481,
            "specValue": 0,
            "name": "Total Games Over/Under",
            "fr_name": "TOTAL DES JEUX PLUS DE/MOINS DE",
            "columns": 2,
            "inColumn": 1,
            "key": "total-games-over-under",
            "selections": [
            {
                "name": "1",
                "shortName": "1",
                "id" : "12"
            },
            {
                "name": "2",
                "shortName": "2",
                "id" : "13"
            }
            ]
        }
        ]
    },
    {
        "sportId": 73743,
        "en_name": "Rugby League",
        "fr_name": "Ligue de Rugby",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 8133,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 3,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id":1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id":2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id":3
                }
                ]
            },
            {
                "id": 8784,
                "specValue": 1,
                "name": "Draw No Bet",
                "fr_name": "REMBOURSÉ SI MATCH NUL",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                "key": "draw-no-bet",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
        ]
    },
    {
        "sportId": 73744,
        "en_name": "Rugby",
        "fr_name": "Rugby",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 15,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 3,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id":1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id":2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id":3
                }
                ]
            },
            {
                "id": 8273,
                "specValue": 0,
                "name": "Double Chance",
                "fr_name": "Double Chance",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                {
                    "name": "1 OR X",
                    "shortName": "1 OU X",
                    "id": 7
                },
                {
                    "name": "X OR 2",
                    "shortName": "X OU 2",
                    "id": 9
                },
                {
                    "name": "1 0R 2",
                    "shortName": "1 OU 2",
                    "id": 8
                }
                ]
            }
        ]
    },
    {
        "sportId": 6,
        "en_name": "Cricket",
        "fr_name": "Cricket",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 8884,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 2,
                "inColumn": 2,
                "print":true,
                "key": "match-winner",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id": 1
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id" : 3
                }
                ]
            },
            {
                "id": 179,
                "specValue": 0,
                "name": "Most Sixes",
                "fr_name": "LA PLUPART DES SIX",
                "columns": 3,
                "inColumn": 1,
                "key": "most-sixes",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id" : 1
                },
                {
                    "name": "X",
                    "shortName": "X",
                    "id":2
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id":3
                }
                ]
            },
            
        ]
    },
    {
        "sportId": 91189,
        "en_name": "Volleyball",
        "fr_name": "Volley-ball",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 6734,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 2,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id" : 1
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id" : 3
                    }
                ]
            },
            {
                "id": 6779,
                "specValue": 0,
                "name": "First Set Winner",
                "fr_name": "GAGNANT DU PREMIER SET",
                "columns": 2,
                "inColumn": 2,
                "key": "first-set-winner",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id": 1
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id" : 3
                }
                ]
            },
            {
                "id": 7012,
                "specValue": 0,
                "name": "Second Set Winner",
                "fr_name": "GAGNANT DU DEUXIÈME SET",
                "columns": 2,
                "inColumn": 2,
                "key": "second-set-winner",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id": 1
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id" : 3
                }
                ]
            }
        ]
    },
    {
        "sportId": 99614,
        "en_name": "Handball",
        "fr_name": "Handball",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 6662,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR",
                "columns": 3,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                    {
                    "name": "1",
                    "shortName": "1",
                    "id" : "1"
                    },
                    {
                    "name": "X",
                    "shortName": "X",
                    "id" : "2"
                    },
                    {
                    "name": "2",
                    "shortName": "2",
                    "id" : "3"
                    }
                ]
            },
            {
                "id": 7290,
                "specValue": 0,
                "name": "Double Chance",
                "fr_name": "Double Chance",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                {
                    "name": "1 OR X",
                    "shortName": "1 OU X",
                    "id":"7"
                },
                {
                    "name": "1 OR 2",
                    "shortName": "1 OU 2",
                    "id":"8"
                },
                {
                    "name": "X 0R 2",
                    "shortName": "X OU 2",
                    "id":"9"
                }
                ]
            }
        ]
    },
    {
        "sportId": 17,
        "en_name": "American Football",
        "fr_name": "Football américain",
        "displayId":"match-result",
        "markets": [
            {
                "id": 6571,
                "specValue": 0,
                "name": "1x2",
                "fr_name": "1x2",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                "print":true,
                "key": "match-result",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":"2"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
            {
                "id": 6788,
                "specValue": 0,
                "name": "Odd or Even Total",
                "fr_name": "TOTAL IMPAIR/PAIR",
                "columns": 2,
                "inColumn": 2,
                "key": "odd-or-even-total",
                "selections": [
                {
                    "name": "odd",
                    "shortName": "impair",
                    "id":32
                },
                {
                    "name": "even",
                    "shortName": "pair",
                    "id": 33
                }
                ]
            },
            {
                "id": 6539,
                "specValue": 0,
                "name": "First Team to Score",
                "fr_name": "HANDICAP ASIATIQUE DEMI-TEMPS",
                "columns": 2,
                "inColumn": 2,
                "key": "first-team-to-score",
                "selections": [
                {
                    "name": "1",
                    "shortName": "1",
                    "id":1
                },
                {
                    "name": "2",
                    "shortName": "2",
                    "id":3
                }
                ]
            }
        ]
    },
    {
        "sportId": 8,
        "en_name": "Darts",
        "fr_name": "Fléchettes",
        "displayId":"match-winner-2-way-",
        "markets": [
            {
                "id": 9670,
                "specValue": 0,
                "name": "Match winner (2 way)",
                "fr_name": "1x2",
                "columns": 2,
                "inColumn": 1,
                "print":true,
                "key": "match-winner-2-way-",
                "selections": [
                    {
                    "name": "1",
                    "shortName": "1",
                    "id" : 25
                    },
                    {
                    "name": "2",
                    "shortName": "2",
                    "id" : 26
                    }
                ]
            }
        ]
    },
    {
        "sportId": 491393,
        "en_name": "Futsal",
        "fr_name": "Futsal",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 7469,
                "specValue": 0,
                "name": "Match Winner",
                "fr_name": "VAINQUEUR DU MACH",
                "bg":"Match Winner ",
                "columns": 3,
                "inColumn": 1,
                "print":true,
                "key": "match-winner",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id": 1
                    },
                    {
                        "name": "X",
                        "shortName": "X",
                        "id":2
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":3
                    }
                ]
            },
            {
                "id": 7772,
                "specValue": 0,
                "name": "Double chance",
                "fr_name": "Double chance",
                "bg": "Double Chance ",
                "columns": 3,
                "inColumn": 2,
                "key": "double-chance",
                "selections": [
                    {
                        "name": "1 OR X",
                        "shortName": "1 OU X",
                        "id": 7
                    },
                    {
                        "name": "X 0R 2",
                        "shortName": "X OU 2",
                        "id": 9
                    },
                    {
                        "name": "1 OR 2",
                        "shortName": "1 OU 2",
                        "id": 8
                    }
                    
                ]
            },
            {
                "id": 8733,
                "specValue": 0,
                "name": "Total Goals (Odd/Even)",
                "fr_name": "TOTAL DES BUTS (IMPAIR/PAIR)",
                "columns": 2,
                "inColumn": 3,
                "key": "total-goals-odd-even-",
                "selections": [
                    {
                        "name": "Odd",
                        "shortName": "Impair",
                        "id": 33
                    },
                    {
                        "name": "Even",
                        "shortName": "Pair",
                        "id": 32
                    }
                ]
            }
        ]
    },
    {
        "sportId": 22,
        "en_name": "Snooker",
        "fr_name": "Billard",
        "displayId":"match-winner",
        "markets": [
            {
                "id": 6672,
                "specValue": 0,
                "name": "Match winner",
                "fr_name": "VAINQUEUR DU MACH",
                "columns": 2,
                "inColumn": 3,
                "print":true,
                "key": "match-winner",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id": 25
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id": 26
                    }
                ]
            }
        ]
    },
    {
        "sportId": 2,
        "en_name": "Aussie Rules",
        "fr_name": "Aussie Rules",
        "displayId":"draw-no-bet",
        "markets": [
            {
                "id": 18,
                "specValue": 0,
                "name": "Draw No Bet",
                "fr_name": "REMBOURSÉ SI MATCH NUL",
                "bg":"Match Result ",
                "columns": 3,
                "inColumn": 1,
                "key": "draw-no-bet",
                "selections": [
                    {
                        "name": "1",
                        "shortName": "1",
                        "id":"1"
                    },
                    {
                        "name": "2",
                        "shortName": "2",
                        "id":"3"
                    }
                ]
            },
        ]
    },
];