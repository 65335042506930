
import ApiService from "../../../services/api.js"
import  *  as types from "../../types"
export const actions = {
    async fetch_slides({commit}) {
        const slides = await ApiService.getSlides()
        commit (types.SET_SLIDES,slides)
    },
    async fetch_jackpot_slides({commit}) {
        const slides = await ApiService.getJackpotSlides()
        commit (types.SET_MEGAJACKPOT_SLIDES,slides)
    },

    async fetch_highlights() {
        await ApiService.getHighlights()
    },  
    async fetch_today_games({commit},payload) {
        const games = await ApiService.getTodayGames(payload)
        if(games.data) {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,games.data)
            } else {
                commit(types.SET_PAGINATED_GAMES,games.data)
            }
            commit(types.SET_META,{ id:payload.id,page:games.nextPage, current:games.currentPage })
        } else {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,[])
            } else {
                commit(types.SET_PAGINATED_GAMES,[])
            }
        }
    },
    async fetch_upcoming_games({commit},payload) {
        const games = await ApiService.getUpcomingGames(payload)
        // games.meta.type = "post"
        // games.meta.post_data = payload
        if(games.data) {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,games.data)
            } else {
                commit(types.SET_PAGINATED_GAMES,games.data)
            }
            commit(types.SET_META,{ id:payload.id,page:games.nextPage, current:games.currentPage })
        } else {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,[])
            } else {
                commit(types.SET_PAGINATED_GAMES,[])
            }
        }
    },
    async fetch_tournament_games({commit},payload) {

        const games = await ApiService.getTournamentGames(payload)
        if(games.data) {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,games.data)
            } else {
                commit(types.SET_PAGINATED_GAMES,games.data)
            }
            commit(types.SET_META,{ id:payload.id,page:games.nextPage, current:games.currentPage })
        } else {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,[])
            } else {
                commit(types.SET_PAGINATED_GAMES,[])
            }
        }
    },
    async fetch_pagination_games({commit},payload) {
        const games = await ApiService.getPagination(payload)
        games.meta.type = payload.type
        games.meta.post_data = payload
        commit(types.SET_PAGINATED_GAMES,games.data)
        commit(types.SET_META,games.meta)
    },
    async fetch_games_by_date({commit},payload) {
        const games = await ApiService.getGamesByDate(payload);
        if(games.data) {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,games.data)
            } else {
                commit(types.SET_PAGINATED_GAMES,games.data)
            }
            commit(types.SET_META,{ id:payload.sportid,page:games.nextPage, current:games.currentPage,date:payload.to })
        } else {
            if(payload.type == 'new') {
                commit(types.SET_GAMES,[])
            } else {
                commit(types.SET_PAGINATED_GAMES,[])
            }
        }
    },
    async fetch_featured_games({commit,dispatch},payload) {
        const games = await ApiService.getFeaturedGames(payload);
        if(games.data) { 
            if(payload.type == 'new') {
                dispatch("set_featured_games");

            } else {
                commit(types.SET_PAGINATED_GAMES,games.data)
            }
            commit(types.SET_META,{ id:payload.id,page:games.nextPage, current:games.currentPage })
        }         
    },
    async fetch_megajackpot_games({commit}) {
        const games = await ApiService.getMegaJackpotsGames();
        commit(types.GET_MEGA_JACKPOT_GAMES,games)
    },
    async fetch_minijackpot_games({commit}) {
        // const games = await ApiService.getMiniJackpotsGames()
        const games = await JSON.parse(localStorage.getItem("featured"));
        commit(types.GET_MINI_JACKPOT_GAMES,games)
    },
   
    async fetch_single_match({commit},payload) {
        const games = await ApiService.getSingleMatch(payload)
        commit(types.SET_SINGLE_MATCH,games)
    },
    get_markets({commit},payload) {
        const market =  ApiService.getMarkets(payload)
        commit(types.GET_MARKETS,market)
    },
    get_all_markets({commit}) {
        const market =  ApiService.getAllMarkets()
        commit(types.SET_MARKETS,market)
    },
    async fetch_sidebar_menu({commit}) {
        const menu =  await ApiService.getSidebarMenu()
        commit(types.GET_SIDEBAR_MENU,menu)
    },
    async fetch_top_menu({commit}) {
        const top5 = await ApiService.getTop5Leagues()
        commit(types.GET_TOP5_MENU,top5)
    },    
    async fetch_leaderboard({commit}) {
        const top5 = await ApiService.getLeadeBoard()
        commit(types.SET_LEADER,top5)
    },
    update_games_state({commit}) {
        commit(
            types.UPDATE_NORMAL_BETSLIP,
            JSON.parse(localStorage.getItem("betslip"))
        )
    },
    update_live_state({commit}) {
        commit(
            types.UPDATE_LIVE_BETSLIP,
            JSON.parse(localStorage.getItem("betslip"))
        )
    },
    remove_games_state({commit}) {
        commit(
            types.UPDATE_NORMAL_BETSLIP,
            JSON.parse(localStorage.getItem("betslip"))
        )
    },
    modify_single_state({commit}) {
        commit(
            types.UPDATE_SINGLE_MATCH,
            JSON.parse(localStorage.getItem("betslip"))
        )
    },
    modify_live_state({commit}) {
        commit(
            types.UPDATE_LIVE_MATCH,
            JSON.parse(localStorage.getItem("betslip"))
        )
    },
    update_jackpot_state({commit},payload) {
        commit(
            types.UPDATE_JACKPOT_BETSLIP, {
                type: payload.type,
                slip: JSON.parse(localStorage.getItem(payload.type))
            }
        )
    },
    set_featured_games({commit}) 
    {
        let featured = JSON.parse(localStorage.getItem("featured"))
        
        commit( types.SET_GAMES,featured);
        commit(types.SET_META,{ id:10,page:1, current:0 })
    },
    set_highlights_games({commit}) 
    {
        let featured = JSON.parse(localStorage.getItem("featured"))
        let highlights = JSON.parse(localStorage.getItem("highlights"))
        let highlights_alt = JSON.parse(localStorage.getItem("highlights_alt"))
    
        if(highlights.length < 15)
        {
            let len = 15 - highlights.length
            
            for (let index = 0; index < len; index++) 
            {
                let val = highlights_alt[index] 

                if( !highlights.find( x => x.id == val.id ) && !featured.find( x => x.id == val.id ) )
                {
                    highlights.push(val)
                }                
            }
        }
        
        commit(
            types.SET_GAMES,
            highlights
        )
    },
    set_slive_game({commit},load) {
        commit(types.SET_SINGLE_LIVE,load)
    },
    set_live_game({commit},load) {
        commit(types.SET_LIVE,load)
    },
    reset_live_game({commit}) {
        commit(types.RESET_LIVE)
    },
    get_live_count({commit}) {
        ApiService.getLiveCount().then( res => {
            commit(types.LIVE_COUNT,res.data)
        }).catch( (err) => {
            console.log(err);
            // commit(types.LIVE_COUNT,null)
        })
       
    },
    get_live_menu({commit}) {
        ApiService.getLiveMenu().then( res => {
            commit(types.LIVE_MENU,res.data)
        }).catch( () => {
            commit(types.LIVE_MENU,[])
        })
       
    },
    get_live_sport({commit},id) {
        ApiService.getLiveSport(id).then( res => {
            commit(types.LIVE_SPORT,res.data)
        }).catch( () => {
            commit(types.LIVE_SPORT,null)
        })
       
    },
    get_live_match({commit},id) {
        ApiService.getLiveMatch(id).then( res => {
            commit(types.SET_SINGLE_LIVE,{ game:res.data[0]})
        }).catch( () => {
            commit(types.SET_SINGLE_LIVE,null)
        })
    },
    set_live_betstop({commit},id) {
        commit(types.LIVE_BETSTOP,id)
    },
    set_live_ended({commit},id) {
        commit(types.LIVE_ENDED,id)
    },
    async fetch_live_slips({commit},payload = null) {
        let tmp = []
        let betslip = JSON.parse(localStorage.getItem("betslip"));
        if(payload) {
            let p = []
            if(betslip) {
                betslip.forEach(el => {
                    if(!payload.includes(el.id) && el.market.type == 'live') {
                        p.push(el.market.oddId);
                    }
                })
                if(p.length > 0) {
                    const games = await ApiService.getLiveSlip(p)
                    if(games) {
                        commit(types.UPDATE_LIVE_SLIPS,games.data);
                    }
                }
            }
        } else {
            if(betslip) {
                betslip.forEach(el => {
                    if(el.market.type == 'live') {
                        tmp.push(el.market.oddId);
                    }
                });
            }
            if(tmp.length > 0) {
                const games = await ApiService.getLiveSlip(tmp)
                if(games) {
                    commit(types.UPDATE_LIVE_SLIPS,games.data);
                }
            }
        }
    },
}