// import Vue from "vue"

export const getters = {
    feeds: state =>  state.games,
    top5: state =>  state.top5,
    menu: state =>  state.menu,
    jackpot_games: state =>  state.jackpot_games,
    daily_jackpot_games: state => state.daily_jackpot_games,
    single_match: state => state.single_match,
    markets: state => state.markets,
    all_markets: state => state.all_markets,
    meta: state => state.meta,
    slides: state => state.slides,
    virtualSlides: state => (state.slides) ? state.slides.filter( x => x.type == 'virtuals') : [],
    casinoSlides: state => (state.slides) ? state.slides.filter( x => x.type == 'casino') : [],
    jackpotSlides: state => (state.slides) ? state.slides.filter( x => x.type == 'megajackpot') : [],
    live: state => state.live,
    live_markets: state => state.live_markets,
    betslip: state => state.betslip,
    liveCount: state => (state.liveCount) ? state.liveCount : null,
    liveMenu: state => (state.liveMenu) ? state.liveMenu : null,
    singleLive: state => (state.single_live) ? state.single_live : null,
}