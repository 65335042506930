export const state = { 
    casino: true,
    casino_type: 'home',
    tableType: 'POPULAR',
    casinoGames:[],
    liveCasinoGames:[],
    virtualGames:[],
    loadingSlots:true,
    loadingLive:true,
    bets:[],
    loadingVirtuals:true,
    lobby:{
      POPULAR:[],
      ROULETTE:[],
      DRAGONTIGER:[],
      MEGAWHEEL:[],
      MEGAROULETE:[],
      ONEBJ:[],
      BACCARAT:[],
      BLACKJACK:[],
      SicBO:[],
      

    }
}