<template>
  <div v-frag>
    <footernav/>
  </div>
</template>
<script>
  import footernav from './footer-nav.vue'
  export default {
    components: {
      footernav
    }
  }
</script>
<style scoped>

</style>