<template>
  <div class="main-betslip">
    <div class="sticky-top">
      <div class="main-betslip-nav">
        <div class="main-betslip-nav-item bet-active">
          {{ $t("betslip.normal") }} ({{ betcount + sharedBet.length }})
        </div>
      </div>

      <div class="main-betslip-header" v-if="betslip || sharedBet.length > 0">
        <span class="" @click="clearAllBets">{{
          $t("betslip-select.remove-all")
        }}</span>
      </div>

      <div class="main-betslip-body" v-if="betslip || sharedBet.length > 0">
        <template v-if="sharedBet">
          <shared
            v-for="(game, i) in sharedBet"
            :key="'sd' + i"
            :match="game"
            :betslip="betslip"
          />
        </template>
        <template v-if="bettype == 'betslip'">
          <match
            v-for="(game, i) in betslip"
            :key="i"
            :match="game"
          />
        </template>

        <br />

        <Alerts
          :type="alert_type"
          :message="alert_message"
          v-if="alert_check"
        />

        <div v-if="oddschange">
          <button class="btn bg-orange btn-block" @click="oddsChange()">
            {{ $t("betslip-select.accept-new-odds") }}
          </button>
        </div>
        <div v-if="betstop">
          <button class="btn bg-orange btn-block" @click="onBetstop()">
            {{ $t("betslip-select.accept-new-odds") }}
          </button>
        </div>
        <div v-if="betstop_all || bet_error || suspended_markets">
          <button class="btn bg-orange btn-block" @click="onBetstop()">
            {{ $t("betslip-select.clear-betslip") }}
          </button>
        </div>
      </div>

      <div class="betslip-match-footer" v-if="betslip || sharedBet.length > 0">
        <div class="betslip-odds-info">
          <div class="betslip-odds-info-div">
            <p>{{ $t("betslip-select.total-odds") }}</p>
            <p>{{ odds }}</p>
          </div>
          <div class="betslip-odds-info-div div-rel">
            <div class="tax" v-if="loc == 'BI' && tax">
              <span @click="closeTax()"><i class="icofont-close-squared-alt" style="margin-right: 0;"></i></span>
              <p> {{ $t("betslip.excise-tax") }} <span>BIF {{ wager.toFixed(0) }}</span></p>
              <p> {{ $t("betslip.stake-after-tax") }} <span>BIF {{ stake.toFixed(0) }}</span></p>
              <p> {{ $t("betslip.withholding-tax") }} <span>BIF {{ withholding.toFixed(0) }}</span></p>
              <p> {{ $t("betslip.possible-payout") }} <span>BIF {{ all.toFixed(0) }}</span></p>
            </div>
            <p>
              {{ $t("betslip-select.possible-win") }} 
              <span @click="closeTax()" v-if="loc == 'BI'">
                <i class="icofont-info-circle"></i>
              </span>

            </p>
            <p>{{ winAmount }}</p>
          </div>
          <div class="bonus text-right" v-if="account.bonus_activated && loggedIn">
              <div class="checkbox">
                  <label class="checkbox-inline">
                      <input type="checkbox" id="use_bonus" v-model="use_bonus">{{ $t('betslip.use-bonus') }}
                  </label>
              </div>
          </div>
        </div>
        <div class="betslip-amount">
          <span class="text-danger bet-error" v-if="amount_error">{{
            amount_error
          }}</span>
          <div class="input-group">
            <span class="input-group-addon">({{ currency.toUpperCase() }}) {{ $t("betslip.amount") }}</span>
            <input
              id="msg"
              type="number"
              v-model="amount"
              min="500"
              class="form-control"
            />
          </div>
        </div>
        <div class="betslip-buttons">
          <button class="btn bg-orange" v-if="!loggedIn" @click="login">
            {{ $t("betslip-select.login") }}
          </button>
          <button
            class="btn bg-orange"
            v-if="loggedIn && bettype == 'betslip' && !bet_delay"
            @click="placeBetslip"
            :class="{ disabled: amount_error || bet_delay }"
          >
            {{ $t("betslip-select.place-bet") }}
          </button>
          <button
            class="btn"
            v-if="bettype == 'betslip'"
            @click="shareBetslip()"
          >
            {{ $t("betslip.share") }}
          </button>
        </div>
      </div>

      <div class="no-betslip" v-if="!betslip && sharedBet.length == 0">
        <input
          type="text"
          class="form-control"
          :placeholder="`${$t('betslip.load-bet')}`"
          v-model="slipCode"
        />
        <button class="btn bg-orange btn-block" @click="getSharedSlip('code')">
          {{ $t("betslip.load-betslip") }}
        </button>
      </div>

      <template v-if="sharedBetError">
        {{ showCodeError() }}
      </template>

      <modal @close="toggleModal" v-if="show">
        <div class="socials">
          <ShareNetwork 
            network="facebook"
            :url="shareUrl"
            title="Rahisibet"
          >
            <span class=""><img src="/images/facebook.png" @click="shareLink('facebook')" alt=""></span>
          </ShareNetwork>
          <ShareNetwork 
            network="twitter"
            :url="shareUrl"
            title="Rahisibet"
          >
          <span class=""><img src="/images/twitter.png" alt=""></span>
          </ShareNetwork>
          <ShareNetwork 
            network="whatsapp"
            :url="shareUrl"
            title="Rahisibet"
          >
          <span class=""><img src="/images/whatsapp.png" alt=""></span>
          </ShareNetwork>
          <ShareNetwork 
            network="telegram"
            :url="shareUrl"
            title="Rahisibet"
          >
          <span class=""><img src="/images/telegram.png" alt=""></span>
          </ShareNetwork>
        </div>
        <div class="">
          <input
            type="text"
            class="form-control"
            v-model="shareCode"
            ref="shareUrl"
          />
          <br>
          <button class="btn bg-orange btn-block" v-clipboard:copy="shareCode">{{ $t('betslip.copy-link') }}</button>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import match from "./../sections/betslip/match.vue";
import shared from "./../sections/betslip/shared.vue";
import { mapGetters, mapActions } from "vuex";
import BetslipService from "@/services/betslip";
import Alerts from "@/components/sections/betslip-sections/alert";
import modal from './modal.vue';
import countryToCurrency from "country-to-currency";

export default {
  components: {
    match,
    Alerts,
    shared,
    modal
  },
  data() {
    return {
      betcount: 0,
      shareUrl:'',
      shareCode:'',
      slipCode: "",
      display: false,
      betslip: [],
      bettype: "betslip",
      amount_error: null,
      amount: 0,
      possiblewin: 1,
      totalodds: 1,
      bonus: 0,
      bet_delay: false,
      use_bonus: false,
      alert_type: "alert-info",
      alert_message: "ok",
      alert_check: false,
      oddschange: false,
      oddschange_data: [],
      betstop: false,
      betstop_all: false,
      suspended_markets: false,
      started_data: false,
      bet_error: false,
      balanceerror: false,
      show:false,
      baseUrl: window.location.origin,
      goe_min: {
        KE: 20,
        UG: 1000,
        TZ: 500,
        RW: 200,
        ZM: 5,
        GH: 2,
        CM: 85,
        BJ: 85,
        CI: 85,
        CD: 500,
        BI: 200,
        XX: 1
      },
      goe_max: {
        KE: 1000000,
        UG: 27000000,
        TZ: 17000000,
        RW: 8000000,
        ZM: 125000,
        GH: 80000,
        CM: 4000000,
        BJ: 4000000,
        CI: 4000000,
        CD: 1000000,
        BI: 100000,
        XX:7000
      },
      wager:0,
      stake: 0,
      withholding:0,
      all:0,
      tax:false
    };
  },
  watch: {
    amount: function (val) {
      this.possiblewin = this.getPossibleWin(val, this.odds);
    },
  },
  mounted() {
    this.betslip = JSON.parse(localStorage.getItem("betslip"));
    this.betcount = this.betslip == null ? 0 : this.betslip.length;
    this.totalodds = this.fixDecimals(this.betslip);
    this.possiblewin = this.getPossibleWin(this.amount, this.odds);
    this.getSharedSlip("load");
  },
  methods: {
    ...mapActions({
      refresh_balance: "get_refresh_balance",
    }),
    toggle: function () {
      this.display = !this.display;
    },
    marketTranslate: function (mkt) {
      if (mkt.mname == "Match Result" ) {
        return "1x2";
      } else if (this.$i18n.te("markets." + mkt.id)) {
       
        let str = this.$i18n.t("markets." + mkt.id, "en");
        let fstr = this.$i18n.t("markets." + mkt.id, "fr");

        if (this.$i18n.locale != "en") {
          let mt = '';
          if(mkt.handicap != 'n/a') {
            mt = fstr + ' ' + mkt.handicap ;
          } else {
            mt = fstr;
          }
          return mt.toLowerCase();
        } else {
          let mt = '';
          if(mkt.handicap != 'n/a') {
            mt = str + ' ' + mkt.handicap ;
          } else {
            mt = str;
          }
          return mt;
        }
      } else if (this.$i18n.te("markets." + mkt.key)) {
        return this.$i18n.t("markets." + mkt.key).toLowerCase();
      } else {
        return mkt.mname.toLowerCase();
      }
    },
    marketSpecifier: function (odd) {
      if (this.$i18n.te("markets." + odd.pick.toLowerCase())) {
        return this.$i18n.t("markets." + odd.oddKey).toLowerCase();
      } else if (this.$i18n.te("markets." + odd.oddKey)) {
        return this.$i18n.t("markets." + odd.oddKey).toLowerCase();
      } else {
        return odd.pick;
      }
    },
    btnChange: function (amount) {
      this.amount = amount;
      this.possiblewin = this.getPossibleWin(this.amount, this.totalodds);
    },
    fixDecimals: function (value) {
      let temp = 1;
      if (value == null && this.sharedBet.length == 0) {
        return temp;
      }
      if (this.sharedBet) {
        this.sharedBet.forEach((x) => (temp *= x.odd));
      }
      if (value) {
        value.forEach((el) => {
          if(el.market.odd > 0 && el.market.status == 1) {
            temp *= el.market.odd;
          }
        });
      }
      return parseFloat(temp.toFixed(2));
    },
    fixSharedDecimals: function (value) {
      let temp = 1;
      if (value == null) {
        return temp;
      }
      value.forEach((el) => {
        temp *= el.odd;
      });
      return parseFloat(temp.toFixed(2));
    },
    getPossibleWin: function (amount, totalodds) {
      if (amount < this.gamount) {
        this.amount_error = `${this.$i18n.t("betslip-select.minimum-bet-amount")} ${this.gamount}`;
        return 0;
      } else if (amount > this.gamountmax) {
        this.amount_error = `${this.$i18n.t("betslip-select.maximum-bet-amount") } ${this.gamountmax}`;
        return 0;
      } else {
        this.amount_error = null;
        if( this.loc == 'BI') {
          this.wager = amount * 0.1;
          this.stake = amount - this.wager;
          let allStake = this.stake * totalodds;
          this.withholding = (allStake - this.stake) * 0.1;

          this.all = allStake - this.withholding;

          return parseInt(this.all.toFixed(0))

        } else {
          let temp = amount * totalodds;
          return parseInt(temp.toFixed(0));
        }
        
      }
    },
    placeBetslip: function () {
      this.bet_delay = true;
      let payload = this.createBetslip(this.betslip, this.amount, "betslip");

      BetslipService.placeBetB(payload)
        .then((res) => {
          if (res.success) {
            this.$swal(
              this.$i18n.t("betslip-select.bet-success"),
              "",
              "success"
            );

            BetslipService.clearBetslip("betslip");
            this.$store.dispatch("removeSharedBets");
            this.refresh_balance();
            this.bet_delay = false;
            this.use_bonus = false;
          } else {
            let notify = [];
            
            if (res.message == "Odds Changed") {
              this.alert_check = true;
              this.oddschange = true;
              this.oddschange_data = res.data;
              this.bet_delay = false;

              notify["type"] = "alert-danger";
              notify["message"] = this.$i18n.t("betslip-select.odds-change");
              this.triggerAlert(notify);
            } else if(res.message == 'Errors placing bet' && res.data[0].account == 'insufficient amount') {
              
              this.alert_check = true;
                this.bet_delay = false;

                notify["type"] = "alert-warning";
                notify["message"] = this.$i18n.t(
                  "betslip-select.balance-error"
                );
                this.triggerAlert(notify);
            } else if(res.message == "Please use 50% of amount deposited") {
              this.bet_delay = false;

              notify["type"] = "alert-danger";
              notify["message"] = this.$i18n.t(
                "betslip.bonus-eng"
              );
              this.triggerAlert(notify);

            } else if (res.message == "Process Failed") { 
              this.bet_delay = false;

              notify["type"] = "alert-danger";
              notify["message"] = this.$i18n.t(
                "betslip-select.bet-error"
              );
              this.triggerAlert(notify);
            }
            else if (res.message == "Game(s) started" || res.message == "Markets Suspended") {

              let ret = res;
              let existingbetslip =JSON.parse(localStorage.getItem("betslip")) || [];
              if (ret.data.started && ret.data.started.length > 0) {
                  if (ret.data.started.length != existingbetslip.length || ret.data.started.length != this.sharedBet.length) {
                      this.alert_check = true;
                      this.betstop = true;
                      this.betstop_all = false;
                      this.bet_delay = false;

                      notify["type"] = "alert-info";
                      notify["message"] =
                        ret.message == "Game(s) has started"
                          ? this.$i18n.t("betslip-select.betstop")
                          : this.$i18n.t("betslip-select.suspended");
                      this.triggerAlert(notify);
                    } else {
                      this.alert_check = true;
                      this.betstop = false;
                      this.betstop_all = true;
                      this.bet_delay = false;

                      notify["type"] = "alert-danger";
                      notify["message"] = this.$i18n.t(
                        "betslip-select.betstop-all"
                      );
                      this.triggerAlert(notify);
                    }
                    this.started_data = ret.data.started;
              } else if(ret.data.suspended && ret.data.suspended.length > 0) {
                  this.started_data = ret.data.suspended;
                  this.alert_check = true;
                  this.betstop = true;
                  this.betstop_all = false;
                  this.bet_delay = false;

                  notify["type"] = "alert-info";
                  notify["message"] =
                    ret.message == "Game(s) has started"
                      ? this.$i18n.t("betslip-select.betstop")
                      : this.$i18n.t("betslip-select.suspended");
                  this.triggerAlert(notify);
              } else {
                if (ret.data.producer_down.length != existingbetslip.length) {
                  this.suspended_markets = true;
                  this.started_data = ret.data.producer_down;
                  this.alert_check = true;
                  this.bet_delay = false;

                  notify["type"] = "alert-danger";
                  notify["message"] = this.$i18n.t(
                    "betslip-select.bet-error"
                  );
                  this.triggerAlert(notify);
                } else {
                  this.started_data = ret.data.producer_down;
                  this.bet_error = true;
                  this.alert_check = true;
                  this.bet_delay = false;

                  notify["type"] = "alert-danger";
                  notify["message"] = this.$i18n.t(
                    "betslip-select.bet-error"
                  );
                  this.triggerAlert(notify);
                }
              } //

            } else if( res.message == 'Required 5 matches and odds more than 150') { 
                  this.bet_delay = false;
                  notify["type"] = "alert-info";
                  notify["message"] = this.$i18n.t("betslip.use-bonus-error");
                  this.triggerAlert(notify);

                  setTimeout(() => {
                    this.$swal(
                    this.$i18n.t("betslip.use-bonus-error-1"),
                    "",
                    "info"
                  );
                  }, 2000);
                  
            } else if( res.message == 'Running bets not allowed') { 
              this.bet_delay = false;
              this.$swal(
                this.$i18n.t("betslip.use-bonus-error-1"),
                "",
                "error"
              );
            } else {
              let ret = res;
              if(ret.message == "Promotion ended") {
                this.alert_check = true;
                this.bet_delay = false;

                notify["type"] = "alert-danger";
                notify["message"] = this.$i18n.t(
                  "betslip.bonus-error"
                );
                this.triggerAlert(notify);
              } else {
                //   if (!ret.data.amount)
                //   {
                this.alert_check = true;
                this.bet_delay = false;

                notify["type"] = "alert-danger";
                notify["message"] = this.$i18n.t(
                  "betslip-select.balance-error"
                );
                this.triggerAlert(notify);
                //   }
              }

              setTimeout(() => {
                this.alert_check = false;
              }, 5000);
            }
          }
        })
        .catch(() => {
          this.bet_delay = false;
          let notify = [];
          notify["type"] = "alert-danger";
          notify["message"] = this.$i18n.t(
            "betslip.bet-error"
          );
          this.triggerAlert(notify);
        });
    },
    shareBetslip: function () {
      let payload = this.createShareBetslip(this.betslip);
      BetslipService.placeSharedBet(payload).then((res) => console.log(res));
    },
    login: function () {
      this.$router.push({ name: "login" });
    },
    clearAllBets: function () {
      this.$swal({
        icon: "warning",
        title: this.$i18n.t("betslip-select.remove-all"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$i18n.t("swal.cancel"),
        confirmButtonText: this.$i18n.t("betslip-select.remove-all"),
      }).then((result) => {
        if (result.value) {
          switch (this.bettype) {
            case "megajackpot":
              BetslipService.clearBetslip("megajackpot");
              break;
            default:
              BetslipService.clearBetslip("betslip");
              this.$store.dispatch("removeSharedBets");
              break;
          }
        }
      });
    },
    createBetslip: function (data, amount, bettype) {
      if (data != null || this.sharedBet.length > 0) {
        const betslip_obj = {
          amount: amount,
          use_bonus: this.use_bonus,
          bettype: bettype,
          multibet:
            ((data && data.length > 1) || this.sharedBet.length > 1) 
              ? true
              : false,
          picks: [],
        };
        if (data) {
          data.forEach((el) => {
            const tmpObj = {};
            tmpObj.fixture = el.id;
            tmpObj.id = el.market.oddId;
            tmpObj.odd = el.market.odd;
            tmpObj.type = el.market.type;
            betslip_obj.picks.push(tmpObj);
          });
        }
        if (this.sharedBet) {
          this.sharedBet.map((el) => {
            const tmpObj = {};
            tmpObj.fixture = el.match_id;
            tmpObj.id = el.odd_id;
            tmpObj.odd = el.odd;
            betslip_obj.picks.push(tmpObj);
          });
        }
        return betslip_obj;
      }
    },
    createShareBetslip: function (data) {
      if (data != null) {
        let code = Math.random().toString(36).toUpperCase().substr(2, 6);
        this.shareCode = code;
        this.shareUrl = `${this.baseUrl}/shared-slip/${code}`;
        this.show = true;
        let obj = {
          code: code,
          payload: [],
        }

        data.forEach((el) => {
          const tmpObj = {};
          tmpObj.name = el.home + " vs " + el.away;
          tmpObj.match_id = el.id;
          tmpObj.isJackpot = false;
          tmpObj.market_id = el.market.mid;
          tmpObj.scheduled = el.scheduled;
          tmpObj.outcome_id = el.market.outcome;
          tmpObj.specifier = el.market.mhandicap != "" ? el.market.mhandicap : "n/a";

          tmpObj.pick = this.marketTranslate(el.market) + " - " + this.marketSpecifier(el.market);
          tmpObj.odd_id = String(el.market.oddId);
          tmpObj.odd = el.market.odd;
          tmpObj.market = this.marketTranslate(el.market);
         

          // let mkt = el.markets.find((x) => x.id === el.selected_marketId);
          // el.markets
          //   .find((x) => x.id === el.selected_marketId)
          //   .odds.forEach((elem) => {
          //     if (
          //       elem.outcomeId == el.selected_odd_outcome &&
          //       elem.id == el.selected_odd_id
          //     ) {
                
          //     }
          //   });
          obj.payload.push(tmpObj);
        });
        return obj;
      }
    },
    triggerAlert: function (notify) {
      this.alert_check = true;
      this.alert_type = notify.type;
      this.alert_message = notify.message;

      setTimeout(() => {
        this.alert_check = false;
      }, 5000);
    },
    oddsChange: function () {
      BetslipService.onOddsChange(this.oddschange_data);

      if (this.oddschange_data) {
        this.oddschange_data.oddsChange.forEach((el) => {
          let betcheck = this.sharedBet.find((x) => x.match_id == el.pick);
          if (betcheck) {
            if (betcheck.odd_id == el.odd_id) {
              betcheck.odd = el.odds;
            }
            this.$store.dispatch("updateSharedbets", betcheck);
          }
        });
      }

      this.oddschange = false;
      this.bet_delay = false;
    },
    onBetstop: function () {
      BetslipService.onBetstop(this.started_data);

      if (this.started_data) {
        this.started_data.forEach((el) => {
          let betcheck = this.sharedBet.find((x) => x.match_id == el);
          if (betcheck) {
            this.$store.dispatch("removeSharedBets", el);
          }
        });
      }

      this.betstop = false;
      this.suspended_markets = false;
      this.betstop_all = false;
      this.bet_delay = false;
      this.alert_check = false;
    },
    megaJackpotPlaceBetslip: function () {
      this.bet_delay = true;
      let payload = this.createBetslip(this.betslip, 2000, "mega_jackpot");

      BetslipService.placeMegaJackpot(payload)
        .then((res) => {
          let notify = [];

          if (res.success) {
            this.$swal(
              this.$i18n.t("betslip-select.bet-success"),
              "",
              "success"
            );

            BetslipService.clearBetslip("megajackpot");
            this.refresh_balance();
            this.bet_delay = false;
          } else {
            if (!res.amount) {
              this.balanceerror = true;
              this.alert_check = true;

              notify["type"] = "alert-danger";
              notify["message"] = this.$i18n.t("betslip-select.balance-error");
              this.triggerAlert(notify);
            } else {
              this.bet_error = true;
              this.alert_check = true;

              notify["type"] = "alert-danger";
              notify["message"] = this.$i18n.t("betslip-select.bet-error");
              this.triggerAlert(notify);
            }

            setTimeout(() => {
              this.bet_error = false;
              this.balanceerror = false;
              this.alert_check = false;
              this.bet_delay = false;
            }, 3000);
          }
        })
        .catch((err) => console.log(err));
    },
    getSharedSlip: async function (arg) {
      let code;
      if (arg == "code") {
        code = { code: this.slipCode };
      } else {
        code = { code: this.$route.params.slip };
      }

      if (code.code) {
        await this.$store.dispatch("fetch_shared_bet", code);
      }
    },
    toggleModal: function() {
      this.show = !this.show;
    },
    showCodeError() {
      this.$swal(
        this.sharedBetError,
        "",
        "error"
      );
    },
    closeTax() {
      this.tax = !this.tax
    }
  },
  computed: {
    ...mapGetters({
      account: "account",
      loggedIn: "token",
      sharedBet: "sharedBet",
      sharedBetError:"sharedBetError",
      geo:'geo',
      user: 'user',
      bets: 'betslip'
    }),
    currency() {
      if(this.user) {
        return (this.goe_min[this.user.iso] == undefined) ? 'USD' : countryToCurrency[this.user.iso]
      } else {
        return (this.geo == null && this.goe_min[this.geo?.country_code2] == undefined) ? 'USD' : countryToCurrency[this.geo?.country_code2]
      }
    },
    loc() {
      if(this.user) {
        return this.user.iso;
      } else {
        return (this.geo != null ) ? this.geo?.country_code2 : 'BI'
      }
    },
    gamount() {
      if(this.user) {
        return (this.goe_min[this.user.iso] == undefined) ? this.goe_min.XX : this.goe_min[this.user.iso]
      } else {
        return (this.geo == null && this.goe_min[this.geo?.country_code2] == undefined) ? this.goe_min.XX : this.goe_min[this.geo?.country_code2]
      }
    },
    gamountmax() {
      if(this.user) {
        return (this.goe_max[this.user.iso] == undefined) ? this.goe_max.XX : this.goe_max[this.user.iso]
      } else {
        return (this.geo && this.goe_max[this.geo?.country_code2] == undefined) ? this.goe_max.XX : this.goe_max[this.geo?.country_code2]
      }
    },
    odds() {
      return this.fixDecimals(this.betslip);
    },
    winAmount() {
      return this.getPossibleWin(this.amount, this.odds);
    },
  },
  created() {
    this.betslip_subscription = BetslipService.getBetslip().subscribe(
      (data) => {
        this.betslip = data;
        this.betcount = data == null ? 0 : data.length;
        this.amount = data == null ? this.gamount : this.amount;
        this.bettype = "betslip";
        this.totalodds = this.fixDecimals(data);
        this.possiblewin = this.getPossibleWin(this.amount, this.totalodds);
      }
    );
    this.jackpot_subscription = BetslipService.getJackpotBetslip(
      "megajackpot"
    ).subscribe((data) => {
      this.betslip = data;
      this.betcount = data == null ? 0 : data.length;
      this.bettype = "megajackpot";
      this.bonus = !!this.account.bonus_activated;
    });
    this.message_subscription = BetslipService.getBetcount().subscribe(
      (data) => {
        if (data) {
          this.$swal.fire(
            this.$i18n.t("betslip-select.maximum-bets"),
            "",
            "error"
          );
        }
      }
    );
  },
  beforeDestroy() {
    // unsubscribe to ensure no memory leaks
    this.betslip_subscription.unsubscribe();
    this.jackpot_subscription.unsubscribe();
  },
};
</script>
<style scoped>
  .input-group-addon,
  .form-control {
    background-color: var(--bg-dark-medium);
    color: var(--a-text);
    border: 1px solid var(--border-color);
  }
  .main-betslip-header span {
    cursor: pointer;
  }
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .socials img {
    /* display: block; */
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .betslip-odds-info p{
    margin-bottom: 4px;
  }
  .div-rel {
    position: relative;
  }
  .tax {
    position: absolute;
    background-color: #1b1d21;
    border-radius: 8px;
    padding: 5px 16px;
    font-size: 12px;
    color: var(--a-grey);
    top:-130px;
    left:70px;
    border: 1px solid var(--a-grey);
    min-width: 160px;
  }
  .tax span {
    display: block;
    text-align: right;
  }
  .tax p span {
    font-weight: 500;
    color: white;
    display: inline;
    font-size: 10px;
    margin-left: 6px;
    
  }

</style>