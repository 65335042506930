import * as types from "../../types"
import ApiService from "../../../services/api"

export const actions = { 
    set_casino({commit},payload) {
        commit(
            types.SET_CASINO,
            payload
        )
    },
    set_casino_type({commit},payload) {
        commit(
            types.SET_CASINO_TYPE,
            payload
        )
    },
    set_casino_live_type({commit},payload) {
        commit(
            types.SET_CASINO_LIVE_TYPE,
            payload
        )
    },
    set_lobby_tables :({commit},payload) =>{
        commit(
            types.SET_LOBBY_TABLE,
            payload
        )
    },
    resetGameLoader:({commit},payload) =>{
        commit(
            types.RESET_LOADER,
            payload
        )
    },

    getCasinoBets: ({commit}) =>{
        ApiService.getCasinoBets().then(res=>{
            commit(
                types.SET_CASINO_BETS,res.data.data
            )
        })
    },
    getCasinoGames : ({commit,dispatch},type="slots") =>{
        ApiService.getCasinoGames().then(res=>{
            if(res.status === 200){
                let games = []
                if(type ==="live"){
                    dispatch("resetGameLoader","LIVE")
                    games = res.data.gameList.filter(item => {
                        return item.gameTypeID == "lg"
                    })
                }else if(type === "virtuals"){
                    dispatch("resetGameLoader","VIRTUALS")
                    games = res.data.gameList.filter(item => {
                        return item.gameTypeID == "rgs-vsb"
                    })
                }else{
                    dispatch("resetGameLoader","SLOTS")
                    games = res.data.gameList.filter(item => {
                        return item.gameTypeID == "vs" || item.gameTypeID == "sc"
                    })
                
                }
                
                commit(
                    types.SET_CASINO_GAMES,
                    {games:games,type:type}
                )
            }else{
                commit(
                    types.CASINO_GAMES_ERROR,
                    res.data
                ) 
            }
        })
    },
    getLiveCasinoGames :({commit}) =>{
        ApiService.getLiveCasinoGames().then(res=>{
            if(res.status === 200){
                commit(
                    types.SET_CASINO_GAMES,
                    res.data.gameList
                )
            }else{
                commit(
                    types.CASINO_GAMES_ERROR,
                    res.data
                ) 
            }
        })
    },
}